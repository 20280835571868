html, body {
	font-size: 14pt !important;
	letter-spacing: 0.2pt;
	line-height: 22pt;
	height: 100%;
}
body
{   
    background: #ffffff; 
    font-family: 'SourceSansPro';
	color: #000000;	
	margin: 0;	
	overflow-y: scroll;
	overflow-x: hidden;
}
body a{
    text-decoration: underline;
	cursor: pointer !important;
}
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.hide { display: none !important; }
.txt-white { color: #fff; }
.text-small {
	font-size: 0.857rem;
}
.text-medium {
	font-size: 1.125rem;
}
.text-large {
	font-size: 1.275rem;
}
.text-semi-bold {
	font-family: 'SourceSansProSemiBold';
}
.text-bold {
	font-family: 'SourceSansProBold';
}
.text-header {
	font-family: 'LatoBold';
}
.text-white {
	color: #ffffff;
}
.text-lightblue { 
	color: #cad0d6; 
}
.text-blue { 
	color: #0d63b0; 
}
.text-blue2 { 
	color: #1776cb;
}
a {
	color: #2973b0 !important;
	text-decoration-color: #548dbf !important;
}
a.no-underline {
	text-decoration: none;
}
button {
	cursor: pointer;
}
*:focus {
	outline-color: #92c8f7;
}
a:hover, a:focus { 
	text-decoration: none !important; 
	color: #3b99e7 !important;
}
a:focus {
	outline: none !important;
}
a.link-lightblue {
	color: #cad0d6 !important;
}
a.link-white {
	color: #ffffff !important;
}
a.link-white:hover, a.link-white:focus, a.header1-text:hover, a.header1-text:focus {
	color: #fabe4f !important;
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}
.clear { 
	clear: both; 
	overflow: hidden; 
}
.v-middle { 
	vertical-align: middle; 
}
.align-v-top {
	display: flex;
	align-items: flex-start;
}
.align-v-middle {
	display: flex;
	align-items: center;
}
.i-block {
	display: inline-block;
}
.relative {
	position: relative;
}

.wrapper-main {
	width: 100%;
	min-height: 100%;
	position: relative;
	margin: 0 auto;
}

.banner-top {
	background-color: #fcc269;
	border-bottom: #6e736e solid 1px;
	padding: 0.3rem 2.2rem;
	font-size:0.9rem;
}

.bannerInfoStyle {
	margin: 0 auto;
	width:90%;
}
.banner-top-text {
	font-family: 'SourceSansProSemiBold';
}

/* Header */
.header1 {
	background-color: #033769;
	border-bottom: #012e57 solid 1px;
	padding: 0.75rem 2.2rem;	
}
.ng-logo-img { 
	vertical-align: middle; 
	width:5rem !important;
	height: auto; 
	margin-right: 0.572rem; 
}
.header1-text { 
	color: #ffffff !important;
	font-size: 1rem; 
}
.nj-logo.large {
	display: flex;
	align-items: center;
}
.nj-logo.large .ng-logo-img {
	width: 59px;
	height: 59px;
}
.nj-logo.large .header1-text {
	font-size: 1.1rem;
}
.btn-toggle {
	background-color: transparent;
	height: 24px;
	border: none;
	color: #000000;
	font-weight: 700;
	font-size: 1.5rem;	
	transform: translateY(-50%);
	position: absolute;
	z-index: 100;
	top: 2rem;
	right: 2.2rem;
	border-radius: 0 0 5px 5px;
	background-color: #fcc269;
	padding: 0 0.5rem;
}
.headerSection{
	background-color: #001f3b;
	border-bottom: #639ed1 solid 1px;
	padding: 0.8rem 2.2rem;
	position: relative;
}

.header2 {
	// background-color: #001f3b;
	// border-bottom: #639ed1 solid 1px;
	// padding: 0.8rem 2.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.header-innerpage {
	background-color: #001f3b;
	border-bottom: #639ed1 solid 1px;
	padding: 1.1rem 12.5rem;
}
.logo-img { 
	vertical-align: middle; 
	width: 280px; 
	height: auto; 
	margin-right: 0.572rem; 
}
.btn-blue { 
	background-color: #1776cb; 
	color: #f9fcfe; 
	padding: 0.75rem 1.2rem; 
	border: none; 
	border-radius: 5px;
	font-family: 'SourceSansProSemiBold'; 
	font-size: 1rem; 
}
.text-register {
	font-family: 'SourceSansProSemiBold';
}
.btn-blue.large {
	padding: 0.78rem 2.3rem;
	font-size: 1.15rem;
}
.p-pull-right {
	float: right;
}

/* Banner */
.banner { 
	position: relative; 	
}
.banner-image1 { 
	background-image: url("/assets/images/img1.png");
	background-repeat: no-repeat;
	max-width: 100%;
	height: 700px;
}
.banner-content {
	position: absolute;
	z-index: 10000;
	top: 0;
	bottom: 0;
	width: 60%;
	height: 100%;	
	overflow: hidden;
}
.banner-content::before {
	// content: "";
	// position: absolute;
	// z-index: -1;
	// bottom: 0;
	// left: 64.3%;
	// width: 100vh;
	// height: 100vh;
	// background-color: rgba(13,99,176,0.85);
	// border-radius: 50%;
	// transform-origin: bottom;
	// transform: translateX(-50%) scale(2) rotate(-70deg);

	content: "";
    position: absolute;
    z-index: -1;
    bottom: -40vh;
    left: -68vh;
    width: 150vh;
    height: 150vh;
    background-color: rgba(13,99,176,.85);
    border-radius: 50%;
    transform-origin: bottom;
}
.banner-content.blue::before{
	background-color: rgba(13,99,176,0.85) !important;
}
.banner-content.green::before{
	background-color: rgba(57,181,74,0.85) !important;	
}

.banner-content.orange::before{
	// background-color: rgba(255, 141, 43, 0.85) !important;
	background-color: rgba(255, 147, 54, 0.85) !important;
	
}

.banner-content-inner .banner-btn.green{
	color: #39B54A !important
}
.banner-content-inner .banner-btn.orange{
	color: #FF8D2B !important
}



	.banner-content-inner {
		// padding: 7.143rem 3.572rem 3.572rem 5.35rem;
		width: 65%;
	}

.banner-content-inner h4, .banner-content-inner .headerStyle{
	// font-family: 'LatoBold';
	color: #ffffff;
}
.banner-content-inner h2 {
	font-family: 'LatoBold';
	font-size: 2rem;
	line-height: 2.7rem;
	color: #ffffff;
	margin-top: 0.83em;
    margin-bottom: 0.83em;

	.font15{
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

}
.banner-content-inner .banner-btn {
	background-color: #ffffff;
	border: none;
	border-radius: 5px;
	padding: 0.8rem 2.6rem;
	font-family: 'SourceSansProSemiBold';
	font-size: 1.1rem;
	color: #0d63b0;
}
.indicators { 
	position: absolute; 
	left: auto;
	bottom: 2rem; 
	z-index: 1000 !important; 
}
.indicators-dot { 
	width: 0.45rem; 
	height: 0.45rem; 
	background: rgba(255,255,255,0.5); 
	display: inline-block; 
	margin: 0.143rem; 
	border-radius: 50%; 
	position: relative; 
	cursor: pointer; 
}
.indicators-dot.sel { 
	background : #ffffff; 
}

/*panel eligible*/
.wrapper-banner-eligible {
	background-color: #ffffff;
	padding: 1.429rem 2.2rem;
}
.panel-eligible {	
	background: #ffffff;
	border: #e6e6e6 solid 1px;
	border-radius: 10px;
	padding: 1.5rem;
	overflow: hidden;
}
.icon-eligible {
	margin-right: 1rem;
}
.eligible-text1, .eligible-text2, .eligible-text3 {
	font-size: 1.275rem;
}
.eligible-text2 {
  font-family: 'SourceSansProSemiBold';
}
.eligible-text3 {
	font-family: 'SourceSansProBold' !important;
}

/* Section completed application */
.completed-app {
	background-color: #f2f5f8;
	padding: 3.5rem 2.2rem;
}
.completed-app h3 { 
	font-family: 'LatoBold';
	font-size: 1.6rem;
	margin: 0 0 1.5rem 0; 
}
.widget-type1 {
	background-color: #ffffff;
	border: #f6f8fa solid 1px;
	padding: 3rem;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.widget-type1 .icon-widget-type1 {
	font-size: 4rem;
}
.widget-type1 .content-widget-type1 {
	font-size: 1.275rem;
}

/* Section What I Expect */
.container-what-expect {
	background-color: #0d63b0;
	padding: 3.5rem 2.2rem 0 2.2rem;
}
.container-what-expect h3 {
	font-family: 'LatoBold';
	font-size: 1.6rem;
	color: #ffffff;
	margin: 0 0 1.5rem 0;
}
.container-what-expect div[class^="p-col"] {
	padding: 1rem;
	padding-bottom: 0 !important;
}
.widget-type2 {
	background-color: #1776cb;
	border-radius: 10px 10px 0 0;
	padding: 2.5rem 2.2rem 1.5rem 2.2rem;
	line-height: 1.75rem;
	min-height: 500px;
	box-sizing: border-box;
}
.widget-type2 .icon-widget-type2 {
	text-align: center;
	min-height: 3.572rem;
}
.widget-type2 .icon-widget-type2 img {	
	height: auto;
}
.circle-num {
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	display: inline-block;
	background-color: #ffffff;
	border-radius: 1.5rem;
	text-align: center;
	color: #0d63b0;
	font-family: 'LatoBold';
	font-size: 1.7rem;
}
.widget-type2 .title-text {
	font-family: 'SourceSansProBold';
	color: #ffffff;
	font-size: 1.5rem;
	margin: 0;
	align-self: center;
}
.widget-type2 p {
	// font-family: 'RobotoRegular';
	font-size: 1.1rem;	
	color: #ffffff;
	margin-top: 0.5rem;
}

/* Section Important Links */
.container-important-links {
	background-color: #ffffff;
	padding: 2.143rem 2.2rem;
}
.container-important-links .headerText {
	font-family: 'LatoBold';
	font-size: 1.6rem;
    margin: 1.429rem 0 3.572rem 0;
}
.widget-type3 {
	text-align: center;
	margin-bottom: 1.8rem;
}
.widget-type3-link {
	display: block;
	color: #000000 !important;
	text-decoration: none;
	cursor: pointer !important;
}
.widget-type3-link:hover {
	color:#000000 !important;
}
.widget-type3-link:focus {
	outline: 0;
	color:#000000;
}
.widget-type3 .btn-circle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.widget-type3 .btn-circle img {
	max-width: 100%;
	height: auto;
}
.widget-type3 .btn-circle img.ilink_img_mouseover {
	display: none;
}
.widget-type3 h5 {
	font-family: 'SourceSansProSemiBold';
	font-size: 1.3rem;
	margin: 0.714rem 0 0.714rem 0;
}
.widget-type3 .btn-circle:hover, .widget-type3-link:focus .btn-circle {
	background-color: #0d63b0;
}
.widget-type3 .btn-circle:hover img:first-child, .widget-type3-link:focus .btn-circle img:first-child {
	display: none;
}
.widget-type3 .btn-circle:hover img.ilink_img_mouseover, .widget-type3-link:focus .btn-circle img.ilink_img_mouseover {
	display: inline;
}

/* Section Call */
.container-call {
	background-color: #eaeef2;
	padding: 2rem 2.2rem;
}
.container-call .icon-call {
	color: #0d63b0;
	font-size: 1.7rem;
}


.mobileLogin,.mobileView{
	// visibility: hidden;
	display: none !important;
}
.desktopLogin{
	// visibility: visible;
	display: inline-block !important;
}

/*Footer*/
.footer {
	background-color: #f2f5f8;
	padding: 1.786rem 2.2rem 1.072rem 2.2rem;
}
.footer-inner {
	// padding: 1.786rem 12.5rem 1.072rem 12.5rem;
	position: absolute;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
}
.footer-section1 {
	border-bottom: #dfe3e8 solid 1px;	
	margin-bottom: 1.429rem;
	padding: 0 25rem;
	padding-bottom: 2.143rem;
	position: relative;
}
.footer-section1 div[class^="p-col"]:nth-of-type(1) {
	width: 60%;
}
.footer-section1 div[class^="p-col"]:nth-of-type(2) {
	width: 40%;
}
.footer-section1 div[class^="p-col"]:nth-of-type(3) {
	width: 30%;
}
.footer-section1 div[class^="p-col"]:nth-of-type(4) {
	width: 30%;
}
.logo-footer-left, .logo-footer-right {
	width: 220px;
	position: absolute;
	top:0;
	// top: 50%;
	// transform: translateY(-50%);
}

.logo-footer-left { left: 0; }
.logo-footer-right { right: 0; }
.logo-footer-left img, .logo-footer-right img {
	width: 100%;
	height: auto;
}
ul,li {
	list-style-type: none; 
	margin: 0; 
	padding: 0;
}
.list-type2 li a { 
	display: block; 
	font-size: 0.9rem; 
	font-family: 'SourceSansProBold';
	color: #1a6bb4;
	text-decoration: none;
	padding: 0.257rem;
}
.list-type2 li a:hover, .list-type2 li a:focus {
	text-decoration: underline !important;
	color: #3b99e7;
}
.menu-footer a {
	text-decoration: none;
	font-size: 0.857rem;
	margin-right: 2.5rem;
}
.menu-footer a:hover {
	text-decoration: underline !important;
}

/* Icons */
.icon-medium {
	font-size: 3rem;
}
.icon-large {
	font-size: 4rem;
}

/* Buttons */
.btn-circle {
	width: 2.143rem;
	height: 2.143rem;
	text-align: center;
	padding: 0;
	font-size: 0.857rem;
	line-height: 1.429rem;
	border-radius: 1.072rem;
	display: inline-block;	
}
.btn-circle.btn-xl {
	width:  8rem;
	height: 8rem;
	padding: 0;
	font-size: 1.714rem;	
	border-radius: 4rem;
}
.btn-xl i { 
	font-size: 3.572rem; 
}
.btn-stroke {
	background-color: transparent;
	border: solid 1px #1776cb;
}
.stroke-blue {
	color: #1776cb;	
}

/* Styles Inner Page */
.container-apply-tab {
	background-color: #ffffff;
	
}
.container-apply-tab-header {
	background-color: #f2f5f8;
	padding: 1.1rem 12.5rem 0 12.5rem;
}
.tab-type1 { 
	overflow: hidden; 
	padding-left: 0.2rem;
}
.tab-type1 li { 
	float: left; 
	padding-right: 3.5rem;
	padding-top: 0.2rem;
	padding-bottom: 0;
}
.tab-type1 li:last-child {
	padding-right: 0;
}
.tab-type1 li a { 
	display: inline-block;
	text-decoration: none;	
	padding: 0.45rem 0.45rem 0.6rem 0.45rem;
	border-bottom: transparent solid 5px;
}
.tab-type1 li a .tab-text {
	color: rgba(0,0,0,0.5);
	font-size: 1.1rem;
}
.tab-type1 li .list-num {
	width: 1.4rem;
	height: 1.4rem;
	line-height: 1.4rem;
	display: inline-block;
	background-color: transparent;
	border: rgba(0,0,0,0.5) solid 1px;
	border-radius: 0.7rem;
	text-align: center;
	color: rgba(0,0,0,0.5);
	font-family: 'SourceSansProSemiBold';
	font-size: 1rem;
	margin-right:0.3rem;
}
.tab-type1 li .list-num span {
	color: rgba(0,0,0,0.5);
}
.tab-type1 li a:hover .tab-text, .tab-type1 li a:focus .tab-text {
	font-weight: 600;
	color: rgba(0,0,0,1);
}
.tab-type1 li a:hover .list-num, .tab-type1 li a:focus .list-num {
	border: rgba(0,0,0,1) solid 1px;
	color: rgba(0,0,0,1);
}
.tab-type1 li.tab-sel a {
	border-bottom: #45a735 solid 5px;
}
.tab-type1 li.tab-sel a .list-num {
	background-color: rgb(0,0,0);
	border: rgba(0,0,0,1) solid 1px;
	color: #ffffff;	
}
.tab-type1 li.tab-sel a .tab-text {
	font-family: 'SourceSansProSemiBold';
	color: rgba(0,0,0,1);
}
.page-sub-header {
	font-family: 'LatoBold';
	font-size: 1.3rem;
	margin-bottom: 1.5rem;
}
.tab-content {
	background-color: #ffffff;	
	padding: 1.1rem 12.5rem 9rem 12.5rem;
	overflow: hidden;
	width: 50%;
	box-sizing: content-box !important;
	text-align:justify;
}
.page-main-header {
	font-family: 'LatoBold';
	font-size: 1.5rem;
	color: #1776cb;
	margin-bottom: 1.7rem;
}
.list-type1 {
	margin: 0;
	padding: 0;
	margin-left: 1.5rem;
}
.list-type1 li {
	list-style: disc;
	list-style-position: outside;
	line-height: 2rem;
	padding-left: 1rem;
    margin-left: 1rem;
}
p.text-large {
	line-height: 2rem;
}
.btn-skip {  
    background-color: #ccc;
    color: #333;
    transition: all 200ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
}
.btn-skip:hover {
    background-color: #1776cb;
    color: #f9fcfe;
}
.btn-blue.btn-icon-left {
	padding-left: 2rem;
}
.btn-blue.btn-icon-left i, .btn-blue.btn-icon-right i {
	position: absolute;	
    top: 50%;
    margin-top: -.5rem;	
}
.btn-blue.btn-icon-left i {
	left: .6rem;
}

@media screen and (min-width: 1360px) and (max-width: 1680px) {
	html, body {
		font-size: 12pt !important;
		line-height: 18pt !important;
	}
	.banner-top {
		padding: 0.714rem 2rem;
	}
	.btn-toggle {
		right: 2rem;
	}
	.header1 {
		padding: 0.75rem 2rem;
	}
	.header2 {
		// padding: 0.8rem 2rem;
	}
	.logo-img {
		width: 220px;
	}
	.aro-check img {
		max-width: 80%;
	}
	.banner-content {
		height: 100%;
		width: 80%;
	}
	.banner-content::before {
		//transform: translateX(-50%) scale(2) rotate(-74deg);
	}
	.banner-content-inner {
		//padding: 7.143rem 3.572rem 3.572rem 2rem;
	}
	.wrapper-banner-eligible {
		padding: 1.429rem 2rem;
	}
	.completed-app {
		padding: 3.5rem 2rem;	
	}
	.container-what-expect { 
		padding: 1.786rem 2rem 0 2rem; 
	}
	.container-what-expect div[class^="p-col"] { 
		padding: 0.5rem; 
	}
	.container-important-links {
		padding: 2.143rem 2rem;
	}
	.container-call {
		    padding: 2rem 2rem;
	}
	.footer {
		padding: 1.786rem 2rem 1.072rem 2rem;	
	}
	.footer-inner {
		padding: 1.786rem 2rem 1.072rem 2rem;	
	}	
	.widget-type2 { 
		min-height: 470px; 
	}
	.widget-type2 .icon-widget-type2 img { 
		width: 40%; 
	}
	.widget-type3 .btn-circle img { 
		max-width: 50%; 
	}
	.header-innerpage {
		padding: 1.1rem 2rem;
	}
	.container-apply-tab-header {
		padding: 1.1rem 2rem 0 2rem;
	}
	.tab-content {
		width: 60%;
		padding: 1.1rem 2rem 9rem 2rem
	}
	
	
}

@media screen and (max-width: 1680px){
	html, body {
		font-size: 12pt !important;
		line-height: 18pt;
	}
	.banner-top {
		padding: 0.714rem 2rem;
	}
	.btn-toggle {
		right: 2rem;
	}
	.header1 {
		padding: 0.75rem 2rem;
	}
	.header2 {
		// padding: 0.8rem 2rem;
	}
	.logo-img {
		width: 220px;
	}
	.aro-check img {
		max-width: 80%;
	}
	.banner-content {
		height: 100%; 
	}
	.banner-content::before {  
		width: 90% !important; 
	}
    .banner-content-inner .text-signin {
        // display: block !important;
    }
	.wrapper-banner-eligible {
		padding: 1.429rem 2rem;
	}
	.completed-app {
		padding: 3.5rem 2rem;	
	}
	.container-what-expect { 
		padding: 1.786rem 2rem 0 2rem; 
	}
	.container-what-expect div[class^="p-col"] { 
		padding: 0.5rem; 
	}
	.container-important-links {
		padding: 2.143rem 2rem;
	}
	.container-call {
		    padding: 2rem 2rem;
	}
	.footer {
		padding: 1.786rem 2rem 1.072rem 2rem;	
	}
	.footer-inner {
		padding: 1.786rem 2rem 1.072rem 2rem;	
	}	
	.widget-type2 { 
		min-height: 470px; 
	}
	.widget-type2 .icon-widget-type2 img { 
		width: 40%; 
	}
	.widget-type3 .btn-circle img { 
		max-width: 50%; 
	}
	.header-innerpage {
		padding: 1.1rem 2rem;
	}
	.container-apply-tab-header {
		padding: 1.1rem 2rem 0 2rem;
	}
	.tab-content {
		width: 60%;
		padding: 1.1rem 2rem 9rem 2rem
	}
	.header-lang-text {		
		bottom: 0 !important;	
	}	
}
@media screen and (min-width: 1761px) and (max-width: 1900px) {
	.banner-content {
		width: 65%;
	}
}
@media screen and (min-width: 1681px) and (max-width: 1760px) {
	.banner-content {
		width: 70%;
	}
	.widget-type1 {
		padding: 2rem;
	}
}
@media screen and (min-width: 1550px) and (max-width: 1680px) {
	.banner-content {
		height: 100%;
	}
	.widget-type2 {
		padding: 4rem 3.25rem;
	}
}
@media screen and (min-width: 375px) and (max-width: 450px) {
	.banner-content {
		width: 120% !important;
	}
	.banner-content:before {
		//transform: translateX(-45%) scale(1.2) rotate(-65deg) !important;
	}
	.banner-content-inner .banner-btn {
		padding: 0.3rem 1rem !important;
		font-size: 0.75rem !important;
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
}

@media screen and (min-width: 375px) and (max-width: 767px) {
	html, body {
		font-size: 12pt !important;
		line-height: 18pt !important;
	}
	.banner-top {
		padding: 0.714rem 1.5rem;
		//position: fixed;
		width: 100%;
		//z-index: 100000;
		//opacity: 0;
		//height: 0;
	}
	.banner-top .banner-top-text {
		display: block;
		margin-bottom: 0.5rem;
		font-size: 0.85rem;
	}
	.banner-top .banner-text-group1 {
		border-bottom: #666666 solid 1px;
		//padding-bottom: 1rem;
	}
	.banner-text-group2 {
		padding-top: 1rem;
	}
	.btn-toggle {
		top: 0;
		right: 1.5rem;
		transform: rotate(180deg);
		border-radius: 5px 5px 0 0;
	}	
	
	// .banner-image1 { 
	// 	background-image: url(assets/images/img1_small.png);
	// 	width: 100%;
	// 	object-fit: cover;
	// 	object-position: top;		
	// 	height: 400px;
	// }
	.banner-content {
		width: 95% !important;
	}
	// .banner-content::before {
	// 	transform: translateX(-45%) scale(1) rotate(-65deg)
	// }

	.banner-content::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -24vh;
    left: -97vh;
    width: 150vh;
    height: 150vh;
    background-color: rgba(13, 99, 176, 0.85);
    border-radius: 50%;
    transform-origin: bottom;
	}
	.banner-content-inner {
		padding: 3rem 1rem 1.5rem 2rem;
	}
	.banner-content-inner h2 {
		font-size: 1.2rem !important;
		line-height: 1.7rem !important;
		letter-spacing: 0.05rem !important;
		margin-top: 2rem !important;
	}
	.banner-content-inner .banner-btn {
		padding: 0.6rem 1rem;
		font-size: 0.95rem;
	}
	.banner-content-inner .text-started, .banner-content-inner .text-signin {
		font-size: 1rem;
	}
	.banner-content-inner .text-signin {
		// display: block;
	}
	.indicators {
		bottom: 1rem;
	}

	.header1 {
		padding: 2rem 1.5rem 0.75rem 1.5rem;
	}
	.header1-text {
		font-size: 0.85rem;
	}
	.header1-text.line2 {
		display: block;
		margin-top: -0.5rem;
	}
	.header1-text.line3 {
		margin-left: 3rem;
	}
	.nj-logo .header1-text span {
		display: block;
	}
	.ng-logo-img {
		// width: 90px;
		// margin-bottom: 0.7rem;
		// float: left;
	}
	.header2 {
		//padding: 1.7rem 1.5rem;
	}
	.header2 .btn-blue {
		display: inline-block;
		padding: 0.5rem 1rem;
		font-size: 0.95rem;
		margin-right: 0!important;
		margin-left:1rem;
		margin-top:0.3rem;
		margin-bottom:0.3rem;
	}
	.logo-img {
		width: 160px;
	}	
	.text-register { 
		display: inline-block;
		margin-left: 0.3rem;
	}
	.text-register a {
		font-size: 1rem;	
	}
	.text-register .new-user {
		display: none;
	}
	.header-lang-text {
		position: absolute;
		z-index: 100000;
		// top: 5rem;
		right: 1.5rem;
		font-family: 'SourceSansPro';
		font-size: 0.8rem;
		
	}

	.wrapper-banner-eligible {
		padding: 1.2rem 1.5rem;
	}
	.panel-eligible {
		padding: 1rem;
	}
	.icon-eligible {
		margin-right: 0 !important;
		font-size: 2.8rem;
	}
	.eligible-text1, .eligible-text2, .eligible-text3 {
		font-size: 1.1rem;
		margin-right: 0!important;
	}
	.eligible-text1 {
		margin-top: 0.85rem;
	}
	.eligible-text3 {
		margin-top: 0;
	}
	.aro-check {
		transform: rotate(90deg);
		transform-origin: center top;
		margin-top: 3.8rem;
		margin-left: 2rem;
	}
	.aro-check img {
		width: 65%;
	}

	.completed-app {
		padding: 1.5rem;
	}
	.completed-app h3 {
		font-size: 1.1rem;
		margin: 0.3rem 0 1rem 0;
	}
	.widget-type1 {
		padding: 1rem;
	}
	.widget-type1 .icon-widget-type1 {
		font-size: 2.7rem;
	}
	.widget-type1 .content-widget-type1 {
		font-size: 1.1rem;
	}

	.container-what-expect {
		padding: 1.786rem 1.5rem 2.5rem 1.5rem;
	}
	.container-what-expect h3 {
		font-size: 1.1rem;
		margin: 0 0 0.5rem 0
	}
	.container-what-expect div[class^="p-col"] {
		padding: 0.5rem !important;
		padding-top: 1rem !important;
	}
	.widget-type2 {
		border-radius: 10px;
		min-height: auto;
		padding: 1.5rem 1.5rem;
	}
	.widget-type2 .icon-widget-type2 {
		margin-top: 1rem;
	}
	.widget-type2 .icon-widget-type2 img {
		transform: scale(0.85);
	}
	.circle-num {
		width: 2.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		border-radius: 1.25rem;
		font-size: 1.6rem;
	}
	.widget-type2 .title-text {
		font-size: 1.15rem;
		margin-top: 0.3rem;
	}

	.container-important-links {
		padding: 1rem 1.5rem;
	}
	.container-important-links .headerText {
		margin: 1rem 0 1.5rem 0;
		font-size: 1.1rem;
	}
	.widget-type2 p {
		font-size: 1rem;
	}
	.btn-circle.btn-xl {
		width: 5.8rem;
		height: 5.8rem;
		border-radius: 2.9rem;
	}
	.widget-type3 {
		margin-bottom: 1.3rem;
	}
	.widget-type3 .btn-circle img {
		max-width: 50%;
	}
	.widget-type3 h5 {
		font-size: 0.95rem;
		margin: 0.5rem 0 0.5rem 0;
	}
	.container-call {
		padding: 1rem;
	}
	.footer-section1 {
		padding: 0;
		border-bottom: 1px solid #c4cad1;
	}
	.footer-section1 div[class^="p-col"]:nth-of-type(1),
	.footer-section1 div[class^="p-col"]:nth-of-type(2),
	.footer-section1 div[class^="p-col"]:nth-of-type(3),
	.footer-section1 div[class^="p-col"]:nth-of-type(4) {
		width: 100%;
		border-bottom: #c4cad1 solid 1px;
		padding-left: 0;
	}
	.footer-section1 div[class^="p-col"]:nth-of-type(4) { 
		padding-bottom: 10rem;
	}
	.list-type2 li a { padding: 0.45rem;}	
	.logo-footer-left, .logo-footer-right {
		transform: none;
		bottom: 0;
		top: auto;
		width: 140px;
		margin: 1rem auto;
   		position: relative;
		 

	}	
	.logo-footer-right { 
		// float: right;
		margin:2rem auto;
	}
	.footer {
		padding: 1.786rem 1.5rem 1.072rem 1.5rem;
	}
	.footer-inner {
		position: relative;
	}
	.footer-section1 {
		border-bottom: #c4cad1 solid 1px;
	}	
	.menu-footer {
		border-bottom: #c4cad1 solid 1px;
		padding-bottom: 0.4rem;
	}
	.menu-footer a {
		display: block;
		margin-right: 0;
		margin-bottom: 1rem;
	}
	.footer-powered {
		margin-top: 1.5rem;
	}
	.footer-copyright {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}
	
	/*** Inner Pages ***/
	.header-innerpage {
		padding: 0;
	}
	.header-innerpage-1 {
		padding: 1rem 1.5rem;
	}
	.header-innerpage-2 {
		background-color: #033769;
		padding: 0 1.5rem 1rem 1.5rem;
		.nj-logo{
			justify-content: start !important;
		}
	}
	.header-innerpage-2 .nj-logo.large {
		align-items: start;
	}
	.header-innerpage-2 .nj-logo.large .ng-logo-img {
		width: 40px;
		height: auto;
	}
	.header-innerpage-2 .nj-logo.large .header1-text.text-line1,
	.header-innerpage-2 .nj-logo.large .text-line2,
	.header-innerpage-2 .nj-logo.large .text-line3 {
		font-size: 0.85rem;
		line-height: normal;
	}
	.header-innerpage-2 .nj-logo.large .header1-text.text-line1 span {
		display: none;
	}
	.header-innerpage-2 .nj-logo.large .text-line3 {
		display: inline-block;
		margin-top: 0.8rem;
	}
	.header-innerpage .text-line4 {
		// position: absolute;
		// right: 1.5rem;
		// top: 0.75rem;
	}
	.container-apply-tab-header {
		padding: 1.1rem 1.1rem 0 1.1rem;
	}
	.wrapper-tab-type1 {
		position: relative;		
	}
	.nav-button.disabled {
		opacity: 0.5;
		cursor: default;
	}
	.nav-left-tab-type1, .nav-right-tab-type1 {
		position: absolute;
		top: 1.35rem;
		font-size: 1.3rem;
		transform-origin: center center;
		text-decoration: none;
		color: #000000;
	}
	.nav-left-tab-type1 {		
		transform: rotate(-90deg);		
	}
	.nav-right-tab-type1 {		
		transform: rotate(90deg);
		right: 0;
	}
	.tab-type1 li {
		padding-right: 0;
	}
	.tab-type1 li.tab-sel {
		flex: 0 0 100%;
		text-align: center;
	}
	.tab-type1 li.tab-sel a {
		border-bottom-width: 3px;
		padding: 1.2rem 0;
	}
	.d-none {
		display: none;
	}
	
	.tab-content {
		padding: 1.1rem 1.3rem 3rem 1.3rem;		
		width: auto;
	}
	.page-main-header {
		font-size: 1.3rem;
		margin-bottom: 0.3rem;
		margin-top: 0.5rem;
	}
	.page-sub-header {
		font-size: 1.1rem;
		margin-top: 0;
		margin-bottom: 0rem !important;
	}
	.page-sub-header.small {
		font-size: 1rem;
	}
	.tab-type1-steps {
		font-size: 1rem;
		color: rgba(0, 0, 0, 0.5);
	}
	.tab-content p {
		margin: 0.5rem 0;
		font-size: 1.1rem;
		text-align: justify;
		line-height: 1.5rem;
	}
	.list-type1 {
		margin-left: 1rem;
	}
	.list-type1 li {
		font-size: 1.05rem;
		line-height: 1.7rem;
		padding-bottom: 0.7rem;
	}
	.list-type1 li:last-child {
		padding-bottom: 0;
	}
	.tab-content .text-large {
		font-size: 1.05rem;
	}
	.btn-blue.large {
		padding: 0.7rem 2.1rem;
		font-size: 1.1rem;
	}


}


@media screen and (min-width: 768px) and (max-width: 1360px) {
	.footer-section1 {
    border-bottom: #dfe3e8 solid 1px;
    margin-bottom: 1.429rem;
    padding: 0 13rem;
    padding-bottom: 2.143rem;
    position: relative;
}
.logo-footer-left, .logo-footer-right {
	
	width: 140px;
	}
	.banner-content-inner { width: 90%; }
}


@media screen and (min-width: 768px) {
	.header-innerpage-2 .nj-logo.large .text-line3 {
		display: none;
	}
	.tab-type1-steps, .nav-left-tab-type1, .nav-right-tab-type1 {
		display: none;
	}
	
}


@media screen and (max-width: 768px) {
	
	.banner-btn.p-mt-md-6 {
		margin-top: 1rem !important;
		}
		.mobileLogin{
			display: inline-block !important;
		}

		.desktopLogin{
			display: none !important;
		}
		.nj-logo{
			border:none !important;
		}

		.mobileView{
			display: block !important;
		}
}


@media screen and (max-width: 960px) {
		
	
		.ui-carousel-dots-container {
			left: 2rem !important;
			bottom: -20px !important;
		}
		.banner-content-inner h2 .font15{
			font-size: 1rem !important;
			line-height: 1.5rem !important;
		}
}
@media screen and (max-width: 1680px){
	.banner-content::before {
		width: 90% !important;
	}
	}
@media screen and (max-width: 1359px){
.banner-content:before {
    width: 105% !important;
    left: -12% !important;
}
}

@media screen and (min-width: 768px) and (max-width: 959px) {
	.header2{
		padding:1rem 0 !important
	}
}

@media screen and (max-width: 767px) {

	.banner-content-inner {
		padding: 0 1rem 0 2rem;
	}
	.banner-content-inner h2 {
		font-size: 1.1rem !important;
		line-height: 1.4rem !important;
		letter-spacing: 0.05rem !important;
		margin: 0 0 0.4rem 0 !important;
	}
	.banner-content-inner .banner-btn {
		padding: 0.6rem 1rem;
		font-size: 0.95rem;
		margin-top: 1rem !important;
		margin-bottom: 1rem;
	}
	.banner-content-inner .text-large {
		line-height: 1.3rem;
	}

	.banner-content-inner {
		padding: 0 1rem 0 2rem;
	}
	.banner-content-inner h2 {
		font-size: 1.1rem !important;
		line-height: 1.4rem !important;
		letter-spacing: 0.05rem !important;
		margin: 0 0 0.4rem 0 !important;
	}
	.banner-content-inner .banner-btn {
		padding: 0.6rem 1rem !important;
		font-size: 0.95rem !important;
		margin-top: 1rem !important;
		margin-bottom: 1rem;
	}
	.banner-content-inner .text-large {
		line-height: 1rem !important;
		font-size: 0.8rem !important;
	}

	.banner-content-inner .banner-btn {
		padding: 0.6rem 1rem;
		font-size: 0.95rem;
	}
	.banner-content-inner .text-started, .banner-content-inner .text-signin {
		font-size: 1rem;
	}
	.banner-content-inner .text-signin {
		// display: block;
	} 

	.p-mt-5.carousalSign{
		margin-top: 0rem !important;
	}
	
	
	
}

@media screen and (min-width: 375px) and (max-width: 767px) {
	.banner-content-inner h2 { font-size: 1rem !important; line-height: 1rem !important; margin-bottom: 0.4rem !important; }
		.banner-content-inner h4, .banner-content-inner .headerStyle { font-size: 0.8rem !important; }

	
}

@media screen and (max-width: 1023px) {
	.banner-content-inner h2 { font-size: 1.2rem !important; line-height: 1.5rem !important; margin-bottom: 0.4rem !important; }
	.banner-content-inner h4, .banner-content-inner .headerStyle { font-size: 1rem !important; }
	.banner-content-inner .text-large { font-size: 1rem !important; line-height: 1.5rem !important; }
	.banner-btn { margin-top: 1rem !important; padding: 0.5rem 2rem !important; margin-bottom: 0.5rem !important; }	
	.banner-content:before { width: 120% !important; left: -20% !important;  }
	.banner-content-inner { padding-left: 3rem !important; }
	body .ui-carousel .ui-carousel-content .ui-carousel-prev, body .ui-carousel .ui-carousel-content .ui-carousel-next{
		font-size: 1rem !important;
	}
	.banner-btn {
		margin-top: 1rem !important;
		padding: 0.5rem 2rem !important;
		margin-bottom: 0.5rem !important;
	}
}



.banner-content { position: absolute; z-index: 10000; top: 0; bottom: 0; width: 60%; height: 100%; overflow: hidden; display: flex;}
.banner-content:before { content: ""; position: absolute; z-index: -1; bottom: -30%; left: -10%; width: 80%; height: 160%; background-color: rgba(13,99,176,.85); border-radius: 50%; transform-origin: bottom; }
.banner-content.blue:before { background-color: rgba(13,99,176,.85)!important }
.banner-content.green:before { background-color: rgba(57,181,74,.85)!important }
.banner-content.orange:before { background-color: rgba(255,147,54,.85)!important }

.banner-content.green .banner-content-inner .banner-btn { color: #39b54a!important }
.banner-content.orange .banner-content-inner .banner-btn { color: #ff8d2b!important }
.banner-content.violet .banner-content-inner .banner-btn { color: #fb3859!important }
.banner-content.violet:before { background-color: rgb(251, 56, 89 ,0.85) !important }
.banner-content.red .banner-content-inner .banner-btn { color: #D0342C !important }
.banner-content.red:before { background-color: rgb(208, 52, 44, 85%)  !important }

.banner-content.yellow:before { background-color: rgba(202,194,0,.85)!important }
.banner-content.yellow .banner-content-inner .banner-btn { color: #b5b405!important }

.banner-content.teal:before { background-color: rgba(9, 157, 148, 0.85) !important }
.banner-content.teal .banner-content-inner .banner-btn { color: #099d94!important }

.banner-content-inner { padding-left: 5.35rem !important; align-self: center; }

.banner-content-inner h4, .banner-content-inner .headerStyle { color: #fff; font-size: 1.3rem !important; font-family: "LatoBold"; margin: 0; letter-spacing: 1.2pt; }
.banner-content-inner h2 { font-family: "LatoBold"; font-size: 2rem !important; line-height: 2.7rem !important; 
	color: #fff; margin-top: 1.2rem !important; margin-bottom: 0.9rem !important; letter-spacing: 1.5pt !important; margin-top: 0 !important; }
.banner-content-inner .banner-btn { background-color: #fff; border: none; border-radius: 5px; padding: .8rem 2.6rem; font-family: SourceSansProSemiBold; font-size: 1.1rem; color: #0d63b0; margin-top: 2.5rem; }
.banner-content p { margin: 0; letter-spacing: 1pt; }

.banner-content:before { content: ""; position: absolute; z-index: -1; bottom: -30%; left: -10%; width: 80%; height: 160%; background-color: rgba(13,99,176,.85); border-radius: 50%; transform-origin: bottom; }

@media screen and (min-width: 375px) and (max-width: 1680px) {
	html, body {
		font-size: 12pt !important;
		line-height: 18pt;
	}
}

@media screen and (min-width: 1761px) and (max-width: 1900px) {
	.banner-content {
		width: 65%;
	}
}
@media screen and (min-width: 1681px) and (max-width: 1760px) {
	.banner-content {
		width: 70%;
	}
	.widget-type1 {
		padding: 2rem;
	}
}
@media screen and (min-width: 1550px) and (max-width: 1680px) {
	.banner-content {
		height: 100%;
	}
	.widget-type2 {
		padding: 4rem 3.25rem;
	}
}
@media screen and (max-width: 1359px) {
   
	.banner-content { height: 100%; }
	.banner-content:before { width: 105% !important; left: -12% !important; }
	.banner-content-inner h2 { font-size: 1.5rem !important; line-height: 1.9rem !important; }
	.banner-content-inner h4, .banner-content-inner .headerStyle { font-size: 1.2rem !important; }

.tab-content {
    width: auto !important;
}
.banner-content-inner h2 .font15{
	font-size: 1.3rem !important;
	line-height: 1.9rem !important;
}
}

@media screen and (max-width: 1023px) {
	.banner-content-inner h2 { font-size: 1.2rem !important; line-height: 1.5rem !important; margin-bottom: 0.4rem !important; }
	.banner-content-inner h4, .banner-content-inner .headerStyle { font-size: 1rem !important;}
	.banner-content-inner .text-large { font-size: 1rem; line-height: 1.5rem; }
	.banner-btn { margin-top: 1rem !important; padding: 0.5rem 2rem; margin-bottom: 0.5rem; }
	
	.banner-content:before { width: 120% !important; left: -20% !important;  }
	.banner-content-inner { padding-left: 3rem !important;width: 90%; }
	.banner-content-inner h2 .font15{
		font-size: 1.1rem !important;
		line-height: 1.5rem !important;
	}
	.logo-img {
		width: 160px;
	}
}




@media screen and (min-width: 375px) and (max-width: 767px) {
	.banner-content-inner h2 { font-size: 1rem !important; line-height: 1rem !important; margin-bottom: 0.4rem !important; }
		.banner-content-inner h4, .banner-content-inner .headerStyle { font-size: 0.8rem !important; }
		.banner-content-inner .text-large {
			line-height: 1rem !important;
			font-size: 0.8rem !important;
		}
		.banner-content-inner h2 .font15{
			font-size: 0.8rem !important;
			line-height: 1rem !important;
		}
}


.widget-type2 .icon-widget-type2 i {
    height: auto;
    align-self: center;
    font-size: 4.5rem;
    color: #FFF;
}


@media screen and (max-width: 1199px){
.widget-type2 .icon-widget-type2 i {
    font-size: 6.5rem;
}
}

.widget-type3 {
	text-align: center;
	margin-bottom: 1.8rem;
}
.widget-type3-link {
	display: block;
	color: #000000;
	text-decoration: none;
	cursor: default;
}
.widget-type3-link:hover {
	color:#000000;
}
.widget-type3-link:focus {
	outline: 0;
	color:#000000;
}
.widget-type3 .btn-circle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.widget-type3 .btn-circle {
	color: #0D63B0;
}
.widget-type3 .widget-type3-link:hover .btn-circle i {
	color: #FFFFFF;
}
.widget-type3 h5 {
	font-family: 'SourceSansProSemiBold';
	font-size: 1.3rem;
	margin: 0.714rem 0 0.714rem 0;
}
.widget-type3 .widget-type3-link:hover .btn-circle, .widget-type3-link:focus .btn-circle {
	background-color: #0d63b0;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
	.headerSection{
        padding:0.8rem 1rem !important;
      }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  
    .logo-img{
      width: 120px !important;
    }
   
      .headerSection{
        padding:0 0.5rem !important;
      }

	  .header2{
		  padding : 0.5rem !important
	  }

	  .header-lang-text{
        right:1rem !important;
		// font-size: 0.6rem !important;
      }
  }