/* You can add global styles to this file, and also import other style files */
@import "assets/scss/layout.scss";
@import "assets/scss/steps.scss";
@import "assets/style.css";
@import "assets/styles-fonts.css";
@import "assets/primeflex.min.css";

@font-face {
  font-family: "heebo-regular";
  src: url(assets/font/heebo/Heebo-Regular.ttf);
}

@font-face {
  font-family: "heebo-bold";
  src: url(assets/font/heebo/Heebo-Bold.ttf);
}
@font-face {
  font-family: "heebo-black";
  src: url(assets/font/heebo/Heebo-Black.ttf);
}
@font-face {
  font-family: "heebo-extrabold";
  src: url(assets/font/heebo/Heebo-ExtraBold.ttf);
}
@font-face {
  font-family: "heebo-light";
  src: url(assets/font/heebo/Heebo-Light.ttf);
}
@font-face {
  font-family: "heebo-medium";
  src: url(assets/font/heebo/Heebo-Medium.ttf);
}
@font-face {
  font-family: "heebo-thin";
  src: url(assets/font/heebo/Heebo-Thin.ttf);
}

@font-face {
  font-family: "AntonioReg";
  src: url(assets/font/Antonio-Regular.woff);
}

:root {
  --nj-header-color: #303946;
  --stepper-active-color: #303946;
  --primary-header-bg: #1facee;
  --primary-header-border: #c6eafb;
  --primary-menu-bg: #65926d;
  --primary-menu-border: #c9d8cc;
  --primary-menu-color: #ffffff;
  --primary-menu-active-color: #668a68;
  --primary-menu-active-bg: #ffffff;
  --primary-menu-active-border-top: #ffffff;
  --secondary-menu-active-border: #8dae93;
  --secondary-menu-active-bg: #65926d;
  --secondary-menu-color: #606e80;
  --button-bg: #2c78f4;
  --button-color: #ffffff;
  --button-hover-bg: #2e67b6;
  --filter-btn-bg: #e6ddf8;
  --filter-btn-border: #aa9acb;
  --filter-btn-color: #000;
  --filter-btn-hover-bg: #efe8ff;
  --select-btn-bg: #dadada;
  --select-btn-border: #d4d4d4;
  --select-btn-active-bg: #7ab261;
  --select-btn-active-hover-bg: #7ebb63;
  --select-btn-color: #676767;
  --select-btn-active-color: #fff;
  --split-btn-bg: #77b55c;
  --split-btn-border: #d0e5c6;
  --active-border: #2c78f4;
  --panel-header-bg: #f2f6f0;
  --panel-header-border: #e6f5e0;
  --panel-header-text-color: #f54326;
  --input-border: #afafaf;
  --input-hover-border: #626262;
  --input-disable-bg: #fafafa;
  --input-disable-lable-color: #b2b2b2;
  --input-disable-border: #e4e4e4;
  --input-icon-bg: #2e67b6;
  --acordion-header-bg: #fafafa;
  --accordion-header-border: #e7e9ea;
  --accordion-header-text-color: #151515;
  --accordion-header-hover-bg: #f0f2f4;
  --accordion-header-active-bg: #d7eccd;
  --accordion-header-active-border: #b6d3aa;
  --left-menu-header-bg: #202a38;
  --left-menu-header-border: #1ca728;
  --left-menu-text-color: #fff;
  --left-menu-item-primary-bg: #41545f;
  --left-menu-item-primary-border: #394953;
  --left-menu-item-secondary-bg: #34444c;
  --left-menu-item-secondary-border: #253136;
  --left-menu-active-bg: #1aab25;
  --left-menu-active-color: #fff;
  --grid-header-bg: #6c8386;
  --grid-header-text-color: #303946;
  --grid-header-border: #739cb1;
  --grid-cell-border-color: #cce7fb;
  --grid-alternate-row-bg: #f4f8fd;
  --grid-row-bg: #fff;
  --grid-selected-row-bg: #d8cdef;
  --grid-selected-row-color: #fff;
  --grid-cell-bg: #ffffff;
  --grid-text-color: #474d55;
  --grid-row-hover: #f3eee4;
  --grid-accordion-row-bg: #fff;
  --grid-paginator-border: #e3e3e3;
  --grid-paginator-disable-bg: #fff;
  --grid-paginator-text-color: #303338;
  --grid-paginator-disable-text-color: #c8c8c8;
  --grid-paginator-active-bg: #88c66c;
  --grid-paginator-active-color: #fff;
  --grid-sort-icon-color: #333;
  --grid-filter-icon-bg: #88c66c;
  --grid-filter-refresh-icon-bg: #ff8a6f;
  --check-default-color: #aaaaaa;
  --check-selected-color: #338e43;
  --associated-menu-border-top: #446644;
  --associated-menu-header-bg: #446644;
  --associated-menu-item-bg: #7da87d;
  --associated-menu-item-text: #ffffff;
  --active-color: #88c66c;
  --profile-bg: #6a9b54;
  --participant-baner-header-bg: #f3eee4;
  --participant-baner-header-border: #e9dec3;
  --participant-baner-header-icon: #65926d;
  --participant-baner-header-color: #33773f;
  --participant-banner-alternate-bg: #f4f4f4;
  --participant-banner-content-label: #4c637b;
  --tab-active-border: #87c56a;
  --tab-active-color: #000;
  --tab-item-border: #d1d8e2;
  --tab-item-bg: #e9edf3;
  --tab-item-hover-bg: #f2f6fb;
  --tab-item-text-color: #738090;
  --editor-tool-bg: #dcefd3;
  --violence-btn-bg: #6879de;
  --violence-btn-hover-bg: #5d6dc7;
  --confidential-btn-bg: #34a7d1;
  --confidential-btn-hover-bg: #2f96bc;
  --special-alert-btn-bg: #ec6f5a;
  --special-alert-btn-hover-bg: #d46451;
  --special-circumstance-btn-bg: #2da55a;
  --special-circumstance-btn-hover-bg: #289451;
  --primary-color: #88c66c;
  --secondary-color: #65926d;
  --secondary-menu-active-bg: #65926d;
  --login-username-color: #ffffff;
  --stepper-active-color: #8d6bd5;
  --stepper-active-circle-bg: #bca7ea;
  --stepper-active-circle-border: #8d6bd5;
  --alert-notify-color: "#FF3CEC";
  --error-color: #ec4d4d;

  --fti-warn-msg: #ff8b6f;
  --primary-font-bold: "SourceSansProBold";
  --primary-font: "SourceSansPro";
  --header-font: "LatoBold";
  --focus-oultline-color: #bbb;
  --boder-highlight: #1d9e30;

  --login-page-bg: #dbe9ed;
  --login-page-stroke: #708a74;
  --white-color: #fff;
  --text-dark-blue: #2b4f8f;
  --text-gray: #6c6c6c;
  --font-size: 16px;
  --red-color: #a80000;
  --grey-color: #e0e0e0;
  --scroll-size: 8px;
  --th-color: #4c4c4c;
}
body {
  .focusable,
  .ui-menubar .ui-menuitem,
  .ui-menubar .ui-menuitem a {
    &:focus {
      /* box-shadow: 0 0 0 0.1em var(--active-border);
      outline: 0;*/
    }
  }
  .ui-dropdown {
    &:focus {
      //    box-shadow: 0 0 0 0.1em var(--active-border);
    }
    .ui-dropdown-trigger {
      &:focus {
        //    box-shadow: 0 0 0 0.1em var(--active-border);
      }
    }
  }
  .p-button {
    &:enabled {
      &:focus {
        //   box-shadow: 0 0 0 0.1em var(--active-border);
      }
    }
  }
}
body {
  .ui-editor-container {
    .ui-editor-toolbar {
      background: var(--editor-tool-bg);
    }
  }
}
body {
  .ui-selectbutton {
    .p-button {
      background: var(--select-btn-bg) !important;
      border: 1px solid var(--select-btn-border) !important;
      color: var(--select-btn-color) !important;

      &.ui-state-active {
        background: var(--select-btn-active-bg) !important;
        color: var(--select-btn-active-color) !important;
        &:hover {
          background: var(--select-btn-active-hover-bg) !important;
        }
      }
    }
  }
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body
  .ui-dropdown-panel
  .ui-dropdown-items
  .ui-dropdown-item-group.ui-state-highlight,
body
  .ui-autocomplete-panel
  .ui-autocomplete-items
  .ui-autocomplete-list-item.ui-state-highlight {
  background: var(--active-border);
}
.padding4px {
  padding: 4px;
}
.text-upper {
  text-transform: uppercase !important;
}
.text-dark-blue {
  color: #355994;
}
.p-grid {
  margin: 0px !important;
}
.clearfix {
  clear: both;
}
.no-pad {
  padding: 0px !important;
}
.btn-gap {
  margin-right: 1.6rem;
}
.relative {
  position: relative;
}
.text-red {
  color: var(--red-color) !important;
}
html {
  font-family: var(--primary-font);
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: var(--font-size);
}
body a {
  color: #355994;
}
body .ui-widget {
  font-family: var(--primary-font);
  font-size: 1rem;
}

body {
  margin: 0;
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-family: var(--primary-font);
  font-size: 1rem;
}
body .ui-dropdown .ui-dropdown-label,
.p-checkbox-label,
.ui-radiobutton-label {
  font-family: var(--primary-font);
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}
.gridRowHeight {
  height: 45px !important;
}
body lib-split-button button.p-button {
  background-color: var(--split-btn-bg) !important;
  border: 1px solid var(--split-btn-border) !important;
  border-right-color: var(--split-btn-bg) !important;
}
body button.p-button {
  color: white !important;
  background-color: var(--button-bg) !important;
  border: 1px solid var(--button-bg) !important;
  border-radius: 5px !important;
}
body .ui-dialog .ui-dialog-titlebar {
  color: white !important;
  background-color: var(--button-bg) !important;
}
body button.p-button:hover {
  background: var(--button-hover-bg) !important;
}
body .ui-inputgroup .p-button {
  background: var(--input-icon-bg) !important;
  border: 1px solid !important;
  border-color: var(--input-icon-bg) !important;
}
body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  background: var(--input-icon-bg) !important;
  border-color: var(--input-icon-bg) !important;
  height: 2.24rem;
  position: relative;
  top: 0px;
  border: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
body .ui-inputgroup .p-button:hover {
  background: var(--input-icon-bg) !important;
}

input,
body .p-inputtext {
  border-color: var(--input-border) !important;
  // text-transform: uppercase;
}
textarea {
  &.p-inputtext {
    text-transform: none;
  }
}
input:hover,
body .p-inputtext:hover {
  border-color: var(--input-hover-border) !important;
}

.HeaderHighlight {
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  background-color: #f6fdff !important;
  box-shadow: 0px -1px 1px #cbc6c6 !important;
}

/****** Grid Filter Style******/
body {
  .p-overlaypanel {
    &.gridFilterColumnsPanel {
      // right: 100% !important;
      // top: -10px !important;
      left: auto !important;
      z-index: 99999 !important;
      right: 210px !important;
      // left: 0 !important;
      bottom: 0;
      top: revert !important;
      .p-overlaypanel-content {
        padding: 7px 0px 5px 0px;
      }
      &.download {
        width: 140px;
      }
      &:after,
      &:before {
        content: none !important;
      }
    }

    &.gridFilterPanel {
      margin-top: 4px;
      margin-left: 11px !important;

      .p-overlaypanel-content {
        padding: 7px 0px 5px 0px;
      }
    }
  }
}

/****** Grid Filter Style End******/

body {
  .p-checkbox {
    .p-checkbox-box {
      height: 1.2rem;
      width: 1.2rem;
      border-color: #79818d !important;
      &:hover {
        border-color: #3b82f6 !important;
      }
      &.p-highlight {
        border-color: #0061ff !important;
        background: #3b82f6;
        top: 4px;
        position: relative;
      }
      &.p-disabled {
        opacity: 0.6;
      }
      .p-checkbox-icon {
        font-size: 1rem;
      }
    }
  }
}

body {
  .p-overlaypanel.selectFilterPanel {
    background-color: #ffffff !important;
    box-shadow: none !important;
    border: 1px solid var(--active-border) !important;
    border-top-color: var(--active-border) !important;
    border-top-width: 3px !important;

    left: auto !important;
    right: 1rem;
    top: 218px !important;
    &.fullWidth {
    }
    &.p-overlaypanel-shifted:after,
    &.p-overlaypanel-shifted:before {
      right: 3.5rem;
    }
    &.p-overlaypanel-shifted:before {
      border-bottom-color: var(--active-border);
    }
    &.p-overlaypanel-shifted:after {
      border-bottom-color: var(--panel-header-bg);
    }
    .p-overlaypanel-content {
      padding: 0px !important;
      box-shadow: none !important;
      border: 0px solid !important;
      .ui-panel {
        box-shadow: none !important;
        .ui-panel-titlebar {
          background-color: var(--panel-header-bg);
          font-family: var(--primary-font-bold) !important;
          box-shadow: none !important;
          color: var(--active-border) !important;
        }
      }
    }
  }
}

.bold {
  font-family: var(--primary-font-bold) !important;
}
.font-normal {
  font-family: var(--primary-font) !important;
  font-weight: normal !important;
}
.text-white {
  color: #fff;
}
.date-input-width {
  width: 250px;
  input.p-inputtext {
    width: 130px !important;
  }
}
.dateInputStyle {
  width: 130px !important;
}
.no-wrap {
  white-space: nowrap !important;
}

.hiddenLabel label.fieldLabel,
.hiddenlabel label.fieldLabel,
div.hiddenLabel > div > label.fieldLabel {
  display: none !important;
}

.cursorPointer {
  cursor: pointer !important;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: white;
}
body {
  .ui-dialog {
    &.ui-dialog-maximized {
      .ui-dialog-content {
        height: calc(100vh - 133px) !important;
      }
    }

    .strtprocessBtnContainer {
      position: absolute;
      right: 0.5rem;
    }
  }
}
/***********************Profile style ::start*************************/

body {
  .headerUserInfoPanel {
    right: 1px !important;
    left: auto !important;
    margin: 0px !important;
    border: none !important;
    z-index: 9999 !important;
    font-size: 1rem;
    font-family: var(--primary-font-bold);
    font-weight: bold;
    width: 25rem;
    &:after {
      top: -13px;
    }
    &:before {
      border-bottom-color: var(--active-border);
    }
    .p-overlaypanel-content {
      border-top: 3px solid var(--active-border);
    }

    .themesMenu {
      button {
        padding: 0.2rem 0.5rem;
        border-radius: 3px;
        min-width: 75px;
        margin-right: 0.2rem;
      }
      &.Green {
        button {
          background: #32b032 !important;
          color: #fff !important;
        }
      }

      &.Blue {
        button {
          background: #3373cb !important;
          color: #fff !important;
        }
      }

      &.Pink {
        button {
          background: #ac47a2 !important;
          color: #fff !important;
        }
      }
    }

    .subItem {
      padding: 0.5rem;
      margin-top: 0.5rem;
      margin-right: 1rem;
      &.themesMenu {
        display: inline-block;
        padding: 0.2rem 0.5rem;
        border: 1px solid #606e80;
        border-radius: 3px;
        min-width: 75px;
        text-align: center;
        &.Blue {
          color: #3373cb;
          border-color: #3373cb;
        }
        &.Pink {
          color: #ac47a2;
          border-color: #ac47a2;
        }
        &.Green {
          color: #32b032;
          border-color: #32b032;
        }
      }
    }
    .p-overlaypanel-content {
      padding: 0px !important;
    }
    .themePanel {
      padding-left: 3rem;
      margin-top: 1rem;
    }
    .userInfoItems {
      color: #606e80;
      .userInfoItem {
        padding: 0.7rem 1rem;
        border-bottom: 1px solid #e1f2f9;
        display: block;
        a {
          color: #606e80;
        }
        &:focus {
          //  box-shadow: 0 0 0 0.1em var(--active-border);
        }
        span {
          padding: 0 1rem;
        }
        &.logout {
          padding: 1.5rem 1rem;
          background: #f1f1f1;
          cursor: pointer !important;
          span {
            color: #e42222;
          }
        }
      }
    }
  }
}

.pointer {
  cursor: pointer !important;
}
.mandatory {
  color: transparent !important;
  font-size: 0.85rem !important;
  padding: 0px 0px 0px 5px;
  display: inline-block;
  max-height: 16px;
  overflow: hidden;
  position: relative;
  top: 2px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mandatory:before {
  content: "\f069";
  color: #a80000;
}

/******************SCROLL STYLE START***********************/

::-webkit-scrollbar:hover {
  width: 12px !important;
}

::-webkit-scrollbar {
  width: 12px !important;
  z-index: 100;
  height: 12px;
}

::-webkit-scrollbar-button {
  width: 12px !important;
  height: 0;
}
::-webkit-scrollbar-button:horizontal:start {
  border-top: #e0e0e0 solid 1px;
  border-bottom: #e0e0e0 solid 1px;
  background: var(--active-border);
}

::-webkit-scrollbar-button:horizontal:end {
  border-top: #e0e0e0 solid 1px;
  border-bottom: #e0e0e0 solid 1px;
  background: var(--active-border);
}

::-webkit-scrollbar-corner {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb:horizontal {
  border: #e0e0e0 solid 1px;
  background: var(--active-border);
}

::-webkit-scrollbar-thumb:vertical {
  border: #e0e0e0 solid 1px;
  background: var(--active-border);
}

::-webkit-scrollbar-track-piece:horizontal {
  border-top: #e0e0e0 solid 1px;
  border-bottom: #e0e0e0 solid 1px;
  background: #fff;
}

::-webkit-scrollbar-track-piece:vertical {
  border-top: #e0e0e0 solid 1px;
  border-bottom: #e0e0e0 solid 1px;
  background: #fff;
}

/****************** SELECT SCROLL STYLE END ********************/

select::-webkit-scrollbar-thumb {
  background: var(--active-border);
  width: 3px;
}

select::-webkit-scrollbar {
  width: 8px !important;
  z-index: 100;
  height: 12px;
}

select::-webkit-scrollbar-button {
  width: 8px !important;
  height: 0;
}

/******************SCROLL STYLE END************************/

/************* Grid custom selection begin*************/
.addcustomSelect {
  background: var(--grid-selected-row-bg) !important;
}
.group-column-border-bottom {
  border-bottom: 0.1px solid var(--grid-cell-border-color);
}
.case-template {
  height: 100%;
  width: 100%;
}
.templatepading {
  padding: 3px !important;
}
.custom-select-first-column {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.custom-select-first-column-text {
  position: relative;
  height: 50%;
  top: 20%;
}
/************* Grid custom selection end*************/
body .ui-state-disabled,
body .ui-widget:disabled,
.ui-dropdown.ui-state-disabled .ui-dropdown-trigger,
.ui-dropdown.ui-state-disabled .ui-dropdown-label {
  background: #efeaea;
  opacity: 1;
}
body {
  .ui-dropdown {
    &.ui-state-disabled {
      .ui-dropdown-trigger {
        display: none;
      }
      .ui-dropdown-label {
        span {
          &.collapse {
            visibility: hidden;
          }
        }
      }
    }
  }
}
.amount {
  text-align: right;
}

/***************** UI VIEW MODE - Starts ***************/
body button.ui-widget:disabled,
body .viewMode button.ui-widget:disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
  background-color: var(--active-border) !important;
}

.viewMode .isdCode {
  left: -8px !important;
}

.viewMode .ui-inputtext:hover,
.viewMode .ui-widget:disabled,
.viewMode .ui-dropdown.ui-state-disabled,
.viewMode .ui-dropdown.ui-state-disabled .ui-dropdown-label,
.viewMode .ui-multiselect.ui-state-disabled,
.viewMode .ui-dropdown,
.viewMode .ui-inputtext,
.viewMode .ui-radiobutton .ui-radiobutton-box,
.viewMode .ui-dropdown .ui-dropdown-label,
.viewMode .ui-multiselect {
  border-color: transparent !important;
  background-color: transparent !important;
  pointer-events: none;
  padding-left: 0 !important;
  text-align: left !important;
  width: 100% !important;
}
.viewMode .ui-radiobutton .ui-radiobutton-box {
  border-color: var(--check-default-color) !important;
}
.viewMode .ui-dropdown .ui-dropdown-trigger,
.viewMode .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button,
.viewMode .ui-inputgroup .p-button.p-button-icon-only {
  display: none;
}
.viewMode .ui-dropdown .defaultOption {
  display: none !important;
}
.viewMode label .mandatory,
.viewMode lib-button {
  display: none;
}
.viewMode label.fieldLabel,
.viewMode .ui-radiobutton-label {
  pointer-events: none;
}
a.disabled {
  opacity: 0.7 !important;
  pointer-events: none;
}
.viewMode .ui-radiobutton {
  cursor: default;
}
.viewMode lib-checkbox div {
  pointer-events: none;
}
.viewMode .stdInput .paddingBottom0::before {
  content: "+";
  position: absolute;
  top: 0px;
  left: -6px;
}
/***************** UI VIEW MODE - Ends ***************/
/**disableMode**/
.disableMode .ui-inputtext:hover,
.disableMode .ui-widget:disabled,
.disableMode .ui-dropdown.ui-state-disabled,
.disableMode .ui-dropdown.ui-state-disabled .ui-dropdown-label,
.disableMode .ui-multiselect.ui-state-disabled,
.disableMode .ui-dropdown,
.disableMode .ui-inputtext,
.disableMode .ui-radiobutton .ui-radiobutton-box,
.disableMode .ui-dropdown .ui-dropdown-label,
.disableMode .ui-multiselect {
  background-color: #efeaea;
  pointer-events: none;
}
.disableMode .p-calendar.p-calendar-w-btn .ui-inputtext,
.disableMode .ui-inputgroup .ui-inputtext:not(:last-child) {
  border-radius: 3px !important;
  border: 1px solid #a6a6a6;
}
.disableMode lib-checkbox div,
.disableMode lib-button {
  pointer-events: none;
}
.disableMode .ui-dropdown .ui-dropdown-trigger,
.disableMode .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button,
.disableMode .ui-inputgroup .p-button.p-button-icon-only {
  display: none;
}
.disableMode label .mandatory {
  display: none;
}
.disableMode button.ui-widget {
  opacity: 0.5;
  background-color: var(--active-border) !important;
}
.disableMode .enabledBtn {
  pointer-events: all !important;
  color: white !important;
  background-color: var(--button-bg) !important;
  border: 1px solid var(--button-color) !important;
}
.disableMode .enabledBtn button.ui-widget {
  opacity: 1;
  pointer-events: all !important;
  background-color: var(--button-bg) !important;
}
.disableMode .ui-dropdown .defaultOption {
  visibility: collapse;
}
/***Ends***/
body .ui-radiobutton .ui-radiobutton-box {
  border-color: var(--check-default-color) !important;
  background-color: #ffffff;
  height: 1.25rem;
  width: 1.25rem;
}
body .ui-radiobutton .ui-radiobutton-box:hover {
  border-color: #303946 !important;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #303946 !important;
  background-color: #ffffff !important;
}
body
  .ui-radiobutton
  .ui-radiobutton-box.ui-widget.ui-state-default.ui-state-active.ui-state-disabled {
  opacity: 0.6;
  pointer-events: none;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: #303946 !important;
}

body .ui-dialog .ui-dialog-footer {
  padding: 0;
  text-align: left;
}

.historyinfolabelstyle {
  font-style: italic;
  font-weight: bold;
}

body .p-inputtext.ng-dirty.ng-invalid {
  border: 1px solid #a6a6a6;
}
.hidelabel label.fieldLabel {
  visibility: hidden !important;
}

as-split-area {
  padding-bottom: 10px;
}
.addTsakNoteTaskDesc {
  width: 100%;
  background: #c3c3c3;
  font-family: var(--primary-font-bold);
  padding: 0.2rem;
}

body {
  .p-overlaypanel {
    // border: none;
    // &:after {
    //   top: -13px;
    // }
    // &:before {
    //   border-bottom-color: #f5a623;
    // }
    // &.searchPanel {
    //   width: 700px;
    // }
    .p-overlaypanel-content {
      padding: 0;
      //   border-top: 3px solid #f5a623;
      p-button {
        width: 100%;
      }
    }
    &.headerOverlaypanel {
      z-index: 9999999999 !important;
    }
  }
}
.deleteIconEnable {
  margin-top: 4px;
  color: red;
  font-size: 18px;
  cursor: pointer !important;
}

.amountInput input {
  text-align: right;
}
.centerAligned input {
  text-align: center;
}
body {
  .screenInfoPanelOveylay {
    &:before {
      left: 0.5rem;
      margin: 0;
    }
    &::after {
      left: 0.5rem;
      margin: 0 0 0 2px;
    }
    width: 50%;
    .p-overlaypanel-content {
      padding: 0;
      .ui-widget-content {
        &.ui-panel {
          &.ui-widget {
            box-shadow: none !important;
          }
        }
      }
      .ui-panel-content {
        padding: 0;
      }
    }
  }
}

body {
  .ui-inputtext:enabled:focus:not(.ui-state-error) {
    border: 1px solid !important;
    border-color: var(--active-border) !important;
  }
  .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    border: 1px solid !important;
    border-color: var(--active-border) !important;
  }
}

/***************** UI Error Set Style - Starts ***************/
.ng-pristine.ng-invalid > div > div > textarea.p-inputtext,
.ng-pristine.ng-invalid > div > div > div > .p-inputtext,
.ng-pristine.ng-invalid > div > div > div > p-inputmask > .p-inputtext,
.ng-pristine.ng-invalid > div > div > p-dropdown > .ui-dropdown,
.ng-pristine.ng-invalid > div > div > select,
.ng-pristine.ng-invalid > div > div > p-multiselect > .p-multiselect,
.ng-pristine.ng-invalid > div > div > p-calendar > .p-calendar > .p-inputtext,
.ng-pristine.ng-invalid
  > div
  > div
  > p-autocomplete
  > .p-autocomplete
  > .p-inputtext:not([disabled]),
body p-calendar.ng-pristine.ng-invalid > .p-calendar > .p-inputtext {
  border: 1px solid #ec4d4d !important;
  border-left-width: 5px !important;
  &:focus {
    border: 1px solid #ec4d4d !important;
    border-left-width: 5px !important;
  }
}
.ng-pristine.ng-invalid
  > div
  > p-checkbox
  > div.p-checkbox
  > div.p-checkbox-box {
  border: 2px solid #ec4d4d !important;
}
app-events-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext,
app-location-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext,
app-case-search-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext,
app-participant-id-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext,
app-other-party-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext,
app-fips-list-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext,
app-source-of-income-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext {
  border-color: var(--input-border) !important;
  border-left-width: 1px !important;
  &:focus {
    border: 1px solid var(--active-border) !important;
  }
}
app-related-soi-popup.ng-dirty.ng-invalid
  .ng-pristine.ng-invalid
  > div
  > div
  > .p-inputtext {
  border-color: var(--input-border) !important;
  border-left-width: 1px !important;
}
app-events-popup.ng-pristine.ng-invalid .p-inputtext,
app-location-popup.ng-pristine.ng-invalid .p-inputtext,
app-case-search-popup.ng-pristine.ng-invalid .p-inputtext,
app-other-party-popup.ng-pristine.ng-invalid .p-inputtext,
app-fips-list-popup.ng-pristine.ng-invalid .p-inputtext,
app-participant-id-popup.ng-pristine.ng-invalid .p-inputtext,
app-source-of-income-popup.ng-pristine.ng-invalid .p-inputtext,
app-related-soi-popup.ng-pristine.ng-invalid .p-inputtext {
  border: 1px solid #ec4d4d !important;
  border-left-width: 5px !important;
  &:focus {
    border: 1px solid #ec4d4d !important;
    border-left-width: 5px !important;
  }
}

body .p-inputtext,
input {
  line-height: 1.3rem;
  font-size: 1rem;
}
input {
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s;
}

.ui-dialog-mask {
  z-index: 9999 !important;
}

body .ui-dialog {
  z-index: 99999 !important;
}
body .p-datepicker,
body .ui-autocomplete-panel,
.ui-dialog .ui-dropdown-panel,
app-error-panel,
lib-side-bar,
body .p-overlaypanel.gridFilterPanel,
body .ui-dropdown-panel {
  z-index: 999999999 !important;
}
body .headerUserInfoPanel {
  z-index: 9999999999 !important;
}
body .helpDialog,
body .confirmDialog {
  z-index: 99999999999 !important;
}
body {
  .helpDialog,
  .confirmDialog {
    + .ui-dialog-mask {
      z-index: 999999999 !important;
    }
  }
}

body .ui-multiselect-panel .ui-multiselect-header {
  display: none !important;
}

/***************** UI Error Set Style-Ends ***************/

/***** Phone Number Style***/

.stdInput .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 45px !important;
}
.stdInput .paddingBottom0::before {
  content: "+";
  position: absolute;
  top: 6px;
  left: 6px;
}
.stdInput .p-inputtext {
  padding-left: 14px !important;
}
.phoneInput .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.phoneGroup {
  display: flex;
}
.stdInput {
  position: relative;
}
.phoneInput {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
/***** Phone Number Style***/

#helpGrid p-table .ui-table .ui-table-tbody tr.headerRow {
  background-color: #6c83864d !important;
}

div:focus,
span:focus,
app-page-container,
.controlSection {
  //outline: -webkit-focus-ring-color auto 0px;
}

div:focus,
span:focus {
  outline: none;
}
.caseIdinput {
  & input {
    text-align: center;
  }
}
.participantIdinput {
  & input {
    text-align: center;
  }
}
.othpinput {
  & input {
    text-align: center;
  }
}

.p-calendar {
  white-space: nowrap !important;
}

.p-paginator .p-disabled {
  pointer-events: none !important;
}
.ui-autocomplete-loader {
  display: none !important;
}
.ui-autocomplete .p-inputtext {
  width: 100% !important;
}
.p-fileupload-choose.p-disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.searchOverlay {
  width: 720px !important;

  .p-tabview {
    .p-tabview-nav {
      background: transparent !important;
    }
    .p-tabview-panels {
      border: 1px solid var(--tab-item-border);
    }
  }
}
// .ui-dropdown-item {
//   text-transform: uppercase;
// }
.quickNavigator {
  .ui-autocomplete-dd {
    .ui-autocomplete-dropdown {
      position: absolute;
      right: 0;
      background: transparent !important;
      color: black !important;
      border: 0 !important;
    }
  }
}
.queryContainer {
  app-events-popup,
  app-location-popup,
  app-case-search-popup,
  app-participant-id-popup {
    label.fieldLabel {
      display: none !important;
    }
    .searchBtn {
      top: -1px;
      margin-top: 0;
    }
  }
}
body .p-overlaypanel .p-overlaypanel-close {
  background: white !important;
  color: var(--button-bg) !important;
  top: 8px !important;
  right: 10px !important;
}

.searchOverlay.p-overlaypanel {
  right: 5px !important;
  left: auto !important;
  &:before {
    margin-right: 360px;
  }
  &:after {
    margin-right: 362px;
  }
}
th {
  .fieldLabel {
    display: none !important;
  }
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #355994 !important;
  text-decoration: underline;
}
.ui-table .ui-column-resizer {
  // cursor: url(assets/images/col-resize.png), auto;
}
.ui-multiselect-panel .ui-multiselect-item span.p-checkbox-icon {
  top: -2px;
  left: 1px;
}

/****** Appointment Tooltip Style *******/
.appointment-panel.p-overlaypanel:after,
.appointment-panel.p-overlaypanel:before {
  padding: 0px !important;
  content: none !important;
}

.appointment-panel-content.p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
  border-top: 1px solid #ccc !important;
}

app-error-panel {
  width: 70%;
}
@media screen and (max-width: 1520px) {
  app-error-panel {
    width: 37% !important;
  }
}

/******* fullcalendar style ******/

body .fc .fc-toolbar .fc-button {
  background-color: #dadada;
  border: 1px solid #6c6c6c;
  color: #333333;
}
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-primary:not(:disabled):active {
  background: var(--select-btn-active-bg) !important;
  color: var(--select-btn-active-color) !important;
  &:hover {
    background: var(--select-btn-active-hover-bg) !important;
  }
}
body .fc .fc-toolbar .fc-today-button {
  color: white !important;
  background-color: var(--button-bg) !important;
  border: 1px solid var(--button-color) !important;
}
/******* fullcalendar style end******/
.radioBtnLabel {
  padding-bottom: 0px !important;
}
.infoIconColor {
  color: #0a2964;
}
.big-label label {
  padding-left: 20px;
}
.big-label p-checkbox {
  position: absolute;
}
app-page-container lib-panel .ui-panel .ui-panel-titlebar.ui-widget-header {
  padding: 0.6vw 10vw 0.6vw !important;
  background: #eff0f1 !important;
  color: #303946 !important;
  font-size: 22px;
}
app-page-container lib-panel .ui-panel .ui-panel-content.ui-widget-content {
  padding: 1vw 10vw 0.7vw !important;
}
app-page-container.tabContainer
  lib-panel
  .ui-panel
  .ui-panel-content.ui-widget-content,
.pageContainer lib-panel .ui-panel .ui-panel-content.ui-widget-content {
  padding: 0px !important;
  position: relative;
}

.pageContainer
  lib-panel
  .dashboardPanel.ui-panel
  .ui-panel-content.ui-widget-content,
.pageContainer
  lib-panel.profilePanel
  .ui-panel
  .ui-panel-content.ui-widget-content {
  padding: 0.5rem 0.2rem 0.5rem 0.5rem !important;
  position: relative;
}

.pageContainer lib-panel .ui-panel .ui-panel-titlebar.ui-widget-header {
  padding: 0.8rem 1rem 0.3rem 1rem !important;
  font-size: 1.3rem;
}

body lib-tab-view.innerTab .p-tabview.p-tabview-top .p-tabview-nav {
  padding-top: 0 !important;
  background: white !important;
}
body lib-tab-view.innerTab .p-tabview.p-tabview-top .p-tabview-nav {
  padding: 1.3vw 0vw 1.1vw 0vw;
}
body lib-tab-view.innerTab .p-tabview .p-tabview-panels {
  padding: 0px !important;
  position: relative;
}
.blueHeader {
  color: #4a8fe2;
}
body .ui-panel .ui-panel-content {
  padding-bottom: 35px;
  padding: 0;
}
.fieldLabel .pi-question-circle {
  position: relative;
  top: 0px;
  font-size: 0.9em !important;
}
fieldset {
  border: 0 !important;
  border-top: 2px solid #eff0f1 !important;
  margin-bottom: 5px;
  padding-inline-start: 0;
  width: 100%;
}

fieldset.default {
  border: 1px solid #eff0f1 !important;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  legend {
    margin-left: 8px;
    font-size: 20px !important;
    color: #3373cb;
  }
}
fieldset.blue {
  legend {
    color: #3373cb;
  }
}

legend {
  font-family: "SourceSansProBold";
  font-size: 30px;
  line-height: 44px;
  color: #303946;
  padding-right: 20px;
}
.overlayOption {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border-bottom: 2px solid #eff0f1;
  .p-button {
    width: 100%;
    text-align: center;
  }
}
body {
  .p-tabview {
    padding: 0;
    .p-tabview-panels {
      border: none !important;

      padding: 1rem 0;
    }
    .p-tabview-panels {
      padding: 2vw 10vw;
    }
    &.p-tabview-top {
      .p-tabview-nav {
        padding: 1.3vw 10vw 1.1vw 10vw;
        background: #fafafa;
        li {
          border: none !important;
          background: transparent !important;
          &.ui-state-disabled {
            opacity: 0.7;
          }
          a {
            color: #6a7380 !important;
            font-family: "SourceSansProBold";
            font-size: 26px;
            text-align: center;
            padding: 0 20px;
          }
          &.p-menuitem-link-active {
            text-align: center !important;
            background: transparent !important;
            border: none;
            border-bottom: 3px solid #f5a623 !important;
            padding: 0px;
            a {
              color: #303946 !important;
            }
          }
        }
      }
    }
  }
}
//login
.login .p-button {
  width: 100% !important;
}
p-button.black .p-button {
  background: #6a7380 !important;
  &:hover {
    background: #6a7380 !important;
  }
  .p-button-text {
    color: #fff !important;
  }
}

p-button.white .p-button {
  background: #fff !important;
  border: 1px solid #6a7380 !important;
  &:hover {
    background: #fff !important;
  }
  .p-button-text {
    color: #303946 !important;
  }
}
p-button.default .p-button {
  background: #f5f5f5 !important;
  border: 1px solid #eaeaea !important;
  &:hover {
    background: #f5f5f5 !important;
  }
  .p-button-text {
    color: #303946 !important;
  }
}
body .p-checkbox.ui-widget {
  position: absolute;
}
body label.checkbox-right-label {
  padding-left: 1.6rem !important;
  font-family: var(--primary-font); //"heebo-regular";
}
body
  .p-steps
  .p-steps-item.ui-state-complete
  .p-menuitem-link
  .p-steps-number:after,
body .p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-number:after,
body
  .p-steps
  .p-steps-item.p-highlight
  .p-menuitem-link
  .p-steps-number:before {
  content: none !important;
}

body
  .p-steps
  .p-steps-item.ui-state-complete:not(.p-disabled)
  .p-menuitem-link
  .p-steps-number:before {
  content: "";
  background: url("assets/images/tick.png") !important;
  background-size: 2em !important;
  position: absolute;
  height: 2em;
  width: 2em;
  left: 0;
  top: 0;
  background-repeat: no-repeat !important;
  margin: 0 !important;
}

body
  .p-steps
  .p-steps-item:not(.p-disabled)
  .p-menuitem-link
  .p-steps-number:before {
  content: "";
  background: url("assets/images/tick.png") !important;
  background-size: 2em !important;
  position: absolute;
  height: 2em;
  width: 2em;
  // left: 0;
  // top: 0;
  background-repeat: no-repeat !important;
  margin: 0 !important;
}

body .p-steps .p-steps-item.p-disabled .p-menuitem-link {
  .p-steps-number {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
}
body .p-steps .p-steps-item .p-menuitem-link {
  cursor: pointer !important;
  overflow: inherit !important;
  border-radius: 0 !important;
  .p-steps-number {
    width: 2rem !important;
    height: 2rem !important;
    background: transparent !important;
    color: rgba(0, 0, 0, 0.5) !important;
    //font-size: 2rem !important;
    // padding: 0.5em 0.8em 0.3em !important;
    text-align: center !important;
    //display: inline-block;
    font-size: 1rem !important;
    text-align: center !important;
    line-height: 2rem !important;

    position: relative;
  }

  .p-steps-title {
    margin-top: 6px !important;
    font-family: var(--primary-font) !important;
    font-size: 1.1rem !important;
    font-weight: normal !important;
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

body .p-steps .p-steps-item .p-menuitem-link:hover {
  .p-steps-title {
    font-weight: 600;
    color: rgb(0, 0, 0) !important;
  }
}

body .p-steps .p-steps-item .p-menuitem-link:focus {
  box-shadow: none !important;
  outline: none !important;
}

.p-steps .p-steps-item .p-menuitem-link:after {
  content: none !important;
}

body .p-steps .p-steps-item .p-menuitem-link.p-menuitem-link-active {
  .p-steps-number {
    background: #303946 !important;
    color: #fff !important;
    border: none !important;
  }
  .p-steps-title {
    color: #303946 !important;
  }
}

body .p-steps .p-steps-item.ui-state-disabled .p-menuitem-link {
  .p-steps-number,
  .p-steps-title {
    color: #bbb !important;
  }
}
.p-steps
  .p-steps-item.ui-state-complete:not(.incomplete).ui-state-disabled
  .p-menuitem-link {
  .p-steps-number {
    color: #bbb !important;
    font-family: var(--primary-font);
    border: 1px solid #bbb !important;
  }
  .p-steps-title {
    color: #bbb !important;
  }
}

body
  .p-steps
  .p-steps-item.ui-state-highlight.ui-state-complete
  .p-menuitem-link
  .p-steps-number {
  color: #b4b4b4 !important;
}
body lib-stepper {
  // padding: 0 10vw;
  // font-size: 0.55rem;
}
body .p-steps {
  font-size: 1em !important;
}
body .p-steps .p-steps-item .p-menuitem-link.p-menuitem-link-active {
  position: relative;
}
body .p-steps .p-steps-item .p-menuitem-link.p-menuitem-link-active:before {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #45a735;
}
.p-steps .p-steps-item.ui-state-complete:not(.incomplete) .p-steps-title,
.p-steps .p-steps-item.ui-state-complete:not(.incomplete) .p-steps-number {
  color: rgba(0, 0, 0, 0.5) !important;
  border: none !important;
}

body .p-steps ul {
  text-align: center;
  background: #f2f5f8 !important;
  display: block;
}
body .p-steps .p-steps-item {
  float: none;
  display: inline-block;
  padding: 0 0.75em;
}
.ui-panel.ui-widget.ui-widget-content {
  box-shadow: none !important;
}
.p-steps-item.ui-state-default.ui-state-disabled.p-steps-incomplete {
  pointer-events: none !important;
}
body .p-button.p-button-text-only .p-button-text {
  padding: 0.5vh 0.7vw;
  font-size: 1vw;
}
.bold label {
  font-family: var(--primary-font-bold);
  margin-bottom: 0 !important;
}
.no-pad-top {
  padding-top: 0 !important;
}
input[ppassword] {
  text-transform: none !important;
}
.dotunderline {
  border-bottom: 1px dotted #355994;
}
body .p-button.p-button-icon-only .p-button-text {
  padding: 0.25em !important;
}
.customPanel {
  box-shadow: 0 0 6px 1px #d9d9d9 !important;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}
.p-table .ui-table .ui-table-thead th {
  background: #3a4886 !important;
}

body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #1f2851 !important;
  background-color: #1f2851 !important;
}
.listitem.active .link {
  color: #fff !important;
}
.ui-panelmenu .ui-menuitem-icon {
  color: #fff;
  font-size: 1.3rem;
  margin-top: -4px;
}

p-table .ui-table .ui-table-thead th label {
  font-family: var(--primary-font-bold) !important;
}
.dockMenu .ui-panelmenu .ui-panelmenu-header a .ui-menuitem-text {
  display: none !important;
}
.dockMenu .ui-menuitem-text {
  display: none !important;
}
.dockMenu .menuHeader lib-link {
  display: flow-root;
}
.fa.fa-power-off {
  color: red;
}
body .gridFilterColumnsPanel .p-checkbox-label,
body .p-overlaypanel .p-checkbox-label {
  position: relative;
  top: -4px;
  padding-left: 17px !important;
}
@media only screen and (max-width: 768px) {
  body .p-button.p-button-text-only .p-button-text {
    font-size: 0.9rem !important;
    padding: 0.429em 1em !important;
  }
}

.unavailablePage {
  text-align: center;
  padding-top: 5em;
}
.unavailablePage h4 {
  min-height: 10em;
}
.overFlowHidden {
  overflow: hidden !important;
}
.determination .p-tabview.p-tabview-top .p-tabview-nav {
  padding: 10px 0px !important;
}
.determination .p-tabview.p-tabview-top .p-tabview-nav {
  background: #fff;
}
.determination .p-tabview .p-tabview-panels {
  padding: 0 !important;
}
.determination
  lib-tab-view
  lib-panel
  .ui-panel
  .ui-panel-content.ui-widget-content {
  padding: 10px !important;
}
.boxBorder {
  border: 1px solid #bebdbd;
  border-radius: 5px;
}
body .ui-inputgroup .ui-inputgroup-addon {
  background: var(--button-bg) !important;
  color: var(--button-color) !important;
}
.ui-panelmenu-header.ui-state-default.ui-state-disabled {
  pointer-events: none !important;
  opacity: 0.7;
}
.pcolPadding {
  padding: 0.5em;
}
.appealsPopup .p-button.p-button-text-only .p-button-text {
  padding: 0.429em 0.629em !important;
}
.wordWrrap {
  word-break: break-word;
}
.visibleHide {
  visibility: hidden;
}
.negativeAmnt {
  color: red;
}
.reComputeError {
  background: #ffd0d0;
  font-size: 1.2em;
  border: 1px solid #bebdbd;
  margin-top: -2px !important;
  padding: 2px !important;
}
.worningIcon {
  vertical-align: top;
  margin-left: 10px;
  color: #d61919;
}
.marginBottom5 {
  margin-bottom: 0.5rem;
}
.uppercase {
  text-transform: uppercase;
}
body .secondDialog {
  z-index: 999999 !important;
}
body {
  .secondDialog {
    + .ui-dialog-mask {
      z-index: 99999 !important;
    }
  }
}
.subSection {
  padding-left: 2em;
}
.subSection::before {
  content: "-";
  position: absolute;
  left: 20px;
}

.displayBlock {
  display: block;
}
.marginBottom0 {
  margin-bottom: 0 !important;
}
.claimDropDown .ui-dropdown-item {
  text-transform: initial !important;
}

/* MYNJHELPS Styles*/

body .p-tabview .p-tabview-panels {
  padding: 0px;
}
body .registerTab .p-tabview.p-tabview-top {
  border: 0 none;
  text-align: center;
  background: #f2f5f8 !important;
}

body .registerTab .p-tabview.p-tabview-top .p-tabview-nav {
  display: inline-table !important;
  padding: 1.3vw 0vw 0vw 0vw !important;
}

body .registerTab .p-tabview .p-tabview-panels .p-tabview-panel {
  text-align: left !important;
}

@media screen and (min-width: 1360px) and (max-width: 1680px) {
  body .p-tabview.p-tabview-top .p-tabview-nav {
    padding: 1.3vw 20vw 0vw 25vw !important;
  }
  .btn-toggle-down {
    right: 2rem !important;
  }
}
body .p-tabview.p-tabview-top .p-tabview-nav {
  background: #f2f5f8 !important;
  // padding: 1.3vw 10vw 0vw 10vw;
  padding: 1.3vw 20vw 0vw 32vw;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:focus {
  box-shadow: none !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li {
  margin-right: 3rem;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a {
  padding: 0 0 0.85rem 0 !important;
  font-size: 1.1rem !important;
}
body .p-tabview.p-tabview-top .p-tabview-nav li a .nj-tab-header,
body .p-tabview.p-tabview-top .p-tabview-nav li a h6 {
  font-size: 1.1rem !important;
  font-family: "SourceSansPro";
  color: rgba(0, 0, 0, 0.5);
  padding: 0px !important;
  margin: 0px !important;
}

body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.ui-state-disabled)
  a
  .nj-tab-header:hover {
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 1);
}

body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.ui-state-active
  a
  .nj-tab-header {
  font-family: "SourceSansProSemiBold" !important;
  color: rgba(0, 0, 0, 1) !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.ui-state-active {
  border-bottom: #45a735 solid 5px !important;
}

.registerTab {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-bottom: #45a735 solid 5px !important;
  }

  .p-tabview .p-tabview-nav {
    display: inline-flex !important;
    // padding:1.3vw 0vw 0vw 0vw !important;
    background: transparent;
    border: none;
  }

  .p-tabview-nav li.p-disabled {
    opacity: 0.4;
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 3rem;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: transparent !important;
    font-family: "SourceSansProSemiBold" !important;
    color: black !important;
    font-size: 1.1rem !important;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1.1rem;
    margin: 0 !important;
  }
  .p-tabview .p-tabview-nav-container {
    border: 0 none;
    text-align: center;
    background: #f2f5f8 !important;
  }

  .p-tabview-nav li.p-highlight a .nj-tab-number {
    display: inline-block;
    font-family: "SourceSansProSemiBold";
    font-size: 1rem;
    background-color: rgb(0, 0, 0);
    border: none;
    color: #ffffff;
  }

  .p-tabview-nav li.p-tabview-ink-bar {
    display: none;
  }

  .p-tabview-nav li a .nj-tab-header {
    font-size: 1.1rem !important;
    font-family: "SourceSansPro";
    color: rgba(0, 0, 0, 0.5);
  }

  .p-tabview-nav li.p-highlight a .nj-tab-header {
    font-family: "SourceSansProSemiBold" !important;
    color: black !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    box-shadow: none;
  }
}

.nj-tab-number {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  display: inline-block;
  background-color: transparent;
  border: rgba(0, 0, 0, 0.5) solid 1px;
  border-radius: 2rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-family: "SourceSansProSemiBold";
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.ui-state-active
  a
  .nj-tab-number {
  display: inline-block;
  font-family: "SourceSansProSemiBold";
  font-size: 1rem;
  background-color: rgb(0, 0, 0);
  border: none;
  color: #ffffff;
  // width: 1.4rem;
  // height: 1.4rem;
  // line-height: 1.4rem;
}

.nj-tab-completed {
  background: url("assets/images/tick.png") !important;
  background-size: 2em !important;
  border: none !important;
  color: transparent !important;
}

.nj-headerstyle {
  background-color: var(--tab-item-bg);
  padding: 12px 15px;
  font-family: var(--header-font);
  //font-size: 1.1rem;
  font-size: 24px !important;
  border-top: 1px solid #ddd;
  color: #303946;
}

.nj-headerstyle,
.nj-tab-content-style {
  padding: 1.1rem 12.5rem !important;
}

.nj-sub-headerstyle {
  // padding:0px 15px;
}

legend,
.nj-sub-headerstyle {
  font-family: var(--header-font);
  //font-size: 1.1rem;
  font-size: 24px !important;
  color: #303946;
  // margin-left: -10px;
}

.boldHeaders {
  font-family: var(--header-font);
  color: #303946;
}

fieldset {
  //  padding: 10px !important;
}

.nj-panel-content {
  //padding: 0px 10px !important;
  padding-left: 1.6rem;
}

.nj-child-indent {
  padding-left: 1.8rem;
}

.resource .nj-panel-content {
  padding-left: 3rem;
}

.nj-help {
  // background-color: var(--tab-item-bg);
  // padding: 10px;
  //color: #a80000;
  text-align: justify;
  color: #1776cb !important;
  i {
    margin-right: 5px;
  }
}

.libRadioContainerBottom {
  padding-bottom: 0px !important;
}

.nj-help-2 {
  background-color: rgb(240, 240, 240);
  color: rgb(34, 167, 240);
  padding: 9px;
}
.nj-subtext {
  color: #999;
  font-size: 0.83rem;
}
.page-sub-header {
  margin-top: 1.5rem !important;
}

label {
  color: #333 !important;
}

.buttonPadding {
  padding: 5px;
}

body .ui-dropdown .ui-dropdown-label-container {
  overflow: hidden !important;
}

// .header-innerpage{
//    padding: 1.5vw 10vw !important;
// }
// .nj-headerstyle, .nj-tab-content-style{
//     padding:12px 10vw !important;
// }
/* MYNJHELPS Styles Ends*/

.p-steps .p-steps-item .p-menuitem-link {
  padding: 0.75em 1em !important;
  background: transparent !important;
}

.no-transform,
.no-transform .p-inputtext,
.no-transform input {
  text-transform: none !important;
}

body .p-carousel .p-carousel-indicators .p-carousel-dot-item > .p-button {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #fff !important;
  width: 10px !important;
  height: 10px !important;
  margin: 1em;
}

body .p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 20px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  // top: -10px;
  position: relative;
}

body .p-carousel-indicator button:enabled:focus {
  box-shadow: 0 0 0 0.3em #ffffff80 !important;
  border: 1px solid transparent;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin: 0 !important;
}

body
  .p-carousel
  .p-carousel-indicators
  .p-carousel-indicator.p-highlight
  button {
  background-color: #fff;
}

.p-carousel-indicators {
  position: absolute;
  //left: 26px;
  left: 4rem;
  // bottom: 30px;
  bottom: -10px;
  z-index: 50000;
}

.indicators {
  display: none !important;
}

.p-carousel-content,
.p-carousel-container {
  padding: 0 !important;
}

body .p-carousel .p-carousel-content .p-carousel-prev,
body .p-carousel .p-carousel-content .p-carousel-next {
  //display: none !important;
  position: absolute;
  z-index: 5;
  background: transparent !important;
  border: none !important;
  text-shadow: 2px 0px 5px black;
  font-size: 2rem;
  padding: 2rem;
}

.p-carousel-prev-icon,
.p-carousel-next-icon {
  font-size: 2rem;
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .p-carousel-prev-icon,
  .p-carousel-next-icon {
    font-size: 1rem;
  }

  body .p-carousel .p-carousel-content .p-carousel-prev,
  body .p-carousel .p-carousel-content .p-carousel-next {
    padding: 1rem !important;
  }
}

body .p-carousel .p-carousel-content .p-carousel-prev {
  left: 0px;
}

body .p-carousel .p-carousel-content .p-carousel-next {
  right: 0;
}

.p-carousel-items-content {
  // overflow: initial !important;
  // width: auto !important;
}

p-table .ui-table .ui-table-thead th {
  color: #ccc !important;
  background: transparent !important;
  border: none !important;
  font-family: 1rem !important;
}

p-table .ui-table .ui-table-tbody td {
  padding: 1em 0.5em !important;
}

p-table .ui-table .ui-table-tbody tr {
  border-bottom: 1px solid #f2f2f2 !important; //#e0effd
  background-color: #fff !important;
}

p-table .ui-table .ui-table-tbody td {
  border: none !important;
}

lib-editable-grid {
  overflow: hidden !important;
}

body p-table.custom-grid .ui-table .ui-table-tbody td {
  padding: 1rem 0.5rem !important;
  text-align: left !important;
  border: none !important;
}

// p-table .ui-table .ui-table-tbody tr:hover{
//   background-color: #eee !important;
// }

body p-table.custom-grid .ui-table .ui-table-tbody td:focus {
  outline: none !important;
}

body .ui-table .ui-table-thead > tr > th {
  text-align: left !important;
  font-size: 1rem !important;
}

.benefits-stepper ul {
  white-space: nowrap;
}

@import "assets/scss/instructionPage.scss";

.banner-content {
  z-index: 9999 !important;
}

.gridActionColumn {
  width: 100px;
  cursor: pointer !important;
  // float:right;
  .gridEdit,
  .gridDelete {
    margin: 0 15px;
  }
}
.gridEdit {
  color: #1949f9;
}
.gridDelete {
  color: #d60404;
}

body .ui-dialog .ui-dialog-titlebar {
  color: #5c5c5c !important;
  background-color: #f2f2f2 !important;
  border-top: 5px solid #1ebf06;
  // border-radius: 5px;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #5c5c5c !important;
}

body .ui-dialog {
  border-radius: 5px !important;
  overflow: hidden;
}

.confirmdialog-icon {
  font-size: 1.1rem !important;
  top: 5px;
  position: relative;
}

.whiteButton .p-button {
  background: #fff !important;
  color: #3373cb !important;
  border: 2px solid !important;
}

.whiteButton .p-button:hover {
  color: #fff !important;
}

.nj-gridform {
  border: 1px solid #e0effd !important;
  padding: 10px;
  border-bottom-width: 3px !important;
}

.p-carousel-items-container .p-carousel-item {
  overflow: hidden !important;
}

.btn-draft button.p-button {
  color: #333 !important;
  background-color: #ccc !important;
  border-color: #eee !important;
  transition: all 200ms cubic-bezier(0.26, 0.86, 0.44, 0.985);
}

.btn-draft button.p-button:hover {
  color: #fff !important;
}
.no-border {
  border: none !important;
}

.no-margin {
  margin: 0px !important;
}

.nj-box-alignment {
  position: relative;
  padding-right: 5% !important;
}

.ui-tooltip {
  max-width: 30rem;
  text-align: justify;
}

.ui-tooltip-text {
  background: #1776cb !important;
  padding: 0.3rem 0.5rem !important;
}

body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #1776cb;
}

.whiteButton .p-button-icon-left {
  top: 54%;
}

.ng-pristine.ng-invalid .p-radiobutton-box {
  border-width: 2px;
}
.lblwithradiotype2.container {
  padding: 0.5rem 0.5rem 0.5rem 3rem;
}

.ng-pristine.ng-invalid .p-radiobutton-box {
  border-width: 2px;
}

.left-menu.ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid transparent;
  background: #fff !important;
  font-size: 18pt;
  font-weight: normal;
  padding: 25px 30px 25px 40px !important;
  border-radius: 0px !important;
  color: #333 !important;
}

.left-menu.admin-left-menu.ui-panelmenu .ui-panelmenu-header > a {
  padding: 22px 3px 22px 10px !important;
  white-space: nowrap !important;
}

.left-menu.ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  position: relative;
  background: #61a6e5 !important;
  border: 1px solid transparent !important;
}

.left-menu.ui-panelmenu
  .ui-panelmenu-header.ui-state-active
  > a
  .ui-menuitem-icon {
  color: #fff !important;
}

.left-menu.ui-panelmenu .ui-panelmenu-header.ui-state-active > a:before,
.left-menu.p-panelmenu .p-panelmenu-header.p-highlight > a:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  background: #0064be;
  left: 0px;
  top: 0;
}

.left-menu.p-panelmenu .caseList.p-panelmenu-header.p-highlight > a::before {
  content: "";
  display: none !important;
}

.left-menu.ui-panelmenu .ui-panelmenu-header > a:focus {
  box-shadow: none !important;
}

.left-menu.p-panelmenu .p-menuitem-icon {
  color: #333 !important;
}

.ui-helper-reset {
  line-height: 22pt !important;
}

body .p-fileupload .p-fileupload-buttonbar,
body .p-fileupload .p-fileupload-content {
  // float : left;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0px;
}
body .p-fileupload .p-fileupload-buttonbar {
  border: none !important;
  background: #fff !important;
  padding: 0 !important;
}

body .p-fileupload .p-fileupload-content {
  padding: 1rem;
  border: none !important;
}

.p-fileupload-row .p-button .pi {
  font-size: 0.83rem !important;
}

.nj-help-padding {
  padding: 15px 0 !important;
}

.p-accordion .p-accordion-header a {
  text-decoration: none !important;
  font-size: 1.1rem;
  font-weight: normal !important;
}

.p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  background: transparent !important;
  border: none !important;
  float: left !important;
}

.p-accordion-header-text {
  padding-left: 15px;
}

.p-accordion .p-accordion-header a:hover {
  background: #ddd !important;
}

body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  font-size: 0.85em !important;
}

body .ui-breadcrumb {
  border: none !important;
  padding: 0px !important;
}

.ui-breadcrumb .p-menuitem-link.ui-state-disabled {
  opacity: 0.4;
  background: #fff !important;
}

body .p-accordion .p-accordion-header a:hover {
  color: #000 !important;
  background: var(--accordion-header-hover-bg) !important;
}

body .p-accordion .p-accordion-header a {
  background: var(--acordion-header-bg) !important;
  border-color: var(--accordion-header-border) !important;
  color: #000 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.85em 1em !important;
}

body .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  height: 22px;
  width: 22px;
  display: inline-block;
  padding: 0 !important;
}

.p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  position: relative;
  font-size: 1rem !important;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  a
  .p-accordion-toggle-icon {
  padding: 0px !important;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  border: none !important;
  background: #fff !important;
  .p-menuitem-link .ui-menuitem-text {
    color: #999 !important;
  }
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.ui-state-active {
  border: none !important;
  border-bottom: 2px solid #45a735 !important;

  .p-menuitem-link .ui-menuitem-text {
    color: #000 !important;
  }
}

body .p-tabmenu .p-tabmenu-nav {
  border: none !important;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:focus {
  box-shadow: none !important;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  padding: 5px !important;
}

.left-menu.p-panelmenu .p-panelmenu-panel .p-menuitem-icon:before {
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
}
.left-menu.p-panelmenu .p-panelmenu-panel:nth-child(1) .p-menuitem-icon:before {
  content: url(assets/images/dash/homeIcn.png);
}
.left-menu.p-panelmenu
  .p-panelmenu-panel:nth-child(1)
  .p-highlight
  .p-menuitem-icon:before {
  content: url(assets/images/dash/homeIcn_sel.png);
}

.left-menu.p-panelmenu .p-panelmenu-panel:nth-child(2) .p-menuitem-icon:before {
  content: url(assets/images/dash/applicationIcn.png);
}
.left-menu.p-panelmenu
  .p-panelmenu-panel:nth-child(2)
  .p-highlight
  .p-menuitem-icon:before {
  content: url(assets/images/dash/applicationIcn_sel.png);
}

.left-menu.p-panelmenu .p-panelmenu-panel:nth-child(3) .p-menuitem-icon:before {
  content: url(assets/images/dash/linkCaseIcn.png);
}
.left-menu.p-panelmenu
  .p-panelmenu-panel:nth-child(3)
  .p-highlight
  .p-menuitem-icon:before {
  content: url(assets/images/dash/linkCaseIcn_sel.png);
}
.left-menu.p-panelmenu .p-panelmenu-panel:nth-child(5) .p-menuitem-icon:before {
  content: url(assets/images/dash/noun-search-map.png);
}
.left-menu.p-panelmenu
  .p-panelmenu-panel:nth-child(5)
  .p-highlight
  .p-menuitem-icon:before {
  content: url(assets/images/dash/noun-search-map.png);
}

.left-menu.p-panelmenu .p-panelmenu-panel:nth-child(4) .p-menuitem-icon:before {
  content: url(assets/images/dash/cases.png);
}

// .left-menu.p-panelmenu .p-panelmenu-panel:nth-child(4) .p-menuitem-icon:before {
//   content: url(assets/images/dash/setting_icn.png);
// }
// .left-menu.p-panelmenu .p-panelmenu-panel:nth-child(4) .p-menuitem-icon:before {
//   content: url(assets/images/dash/setting_icn.png);
// }

.left-menu.p-panelmenu .p-panelmenu-panel .p-submenu-list {
  .p-menuitem {
    position: relative;
  }
  .p-menuitem:nth-child(1) .p-menuitem-icon:before {
    content: url(assets/images/dash/caseSumIcn.png);
  }
  .p-menuitem:nth-child(2) .p-menuitem-icon:before {
    content: url(assets/images/dash/uploadDocIcn.png);
  }
  .p-menuitem:nth-child(3) .p-menuitem-icon:before {
    content: url(assets/images/dash/myNoticeIcn.png);
  }
  // .p-menuitem:nth-child(4) .p-menuitem-icon:before {
  //   content: url(assets/images/dash/contact-county.png);
  // }
  .p-menuitem:nth-child(4) .p-menuitem-icon:before {
    content: url(assets/images/dash/alertsCommunication.png);
  }

  //   .p-menuitem:nth-child(4):before {
  //     content: "";
  //     background-color: #7ebcfc;
  //     width: 40px;
  //     height: 20px;
  //     border-radius: 10px;
  //     position: absolute;
  //     right: 40px;
  //     top: 15px;
  // }

  .p-menuitem:nth-child(5) .p-menuitem-icon:before {
    content: url(assets/images/dash/actions.png);
  }

  .p-menuitem.actionMenu {
    .p-menuitem:nth-child(1) .p-menuitem-icon:before {
      content: url(assets/images/dash/irfIcn.png);
    }
    .p-menuitem:nth-child(2) .p-menuitem-icon:before {
      content: url(assets/images/dash/contact-county.png);
    }
  }
}

.p-panelmenu
  .p-panelmenu-content
  .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 2rem;
}

.left-menu.p-panelmenu .p-panelmenu-panel .p-submenu-list {
  .p-menuitem:nth-child(1) .p-menuitem-link-active .p-menuitem-icon:before {
    content: url(assets/images/dash/caseSumIcn_sel.png);
  }
  .p-menuitem:nth-child(2) .p-menuitem-link-active .p-menuitem-icon:before {
    content: url(assets/images/dash/uploadDocIcn_sel.png);
  }
  .p-menuitem:nth-child(3) .p-menuitem-link-active .p-menuitem-icon:before {
    content: url(assets/images/dash/myNoticeIcn_sel.png);
  }
  // .p-menuitem:nth-child(4) .p-menuitem-icon:before {
  //   content: url(assets/images/dash/contact-county.png);
  // }
  .p-menuitem:nth-child(4) .p-menuitem-link-active .p-menuitem-icon:before {
    content: url(assets/images/dash/alertsCommunication_sel.png);
  }

  .p-menuitem:nth-child(5) .p-menuitem-link-active .p-menuitem-icon:before {
    content: url(assets/images/dash/actions_sel.png);
  }

  .p-menuitem.actionMenu {
    .p-menuitem:nth-child(1) .p-menuitem-link-active .p-menuitem-icon:before {
      content: url(assets/images/dash/irfIcn_sel.png);
    }
    .p-menuitem:nth-child(2) .p-menuitem-link-active .p-menuitem-icon:before {
      content: url(assets/images/dash/contact-county-select.png);
    }
  }

  .p-menuitem .p-menuitem-link-active {
    .p-menuitem-text {
      color: #fff;
    }
  }
}
// .left-menu.p-panelmenu .p-panelmenu-panel .p-submenu-list {
// .p-menuitem .submenu p-panelmenusub .p-submenu-list {
//   .p-menuitem .p-menuitem-icon:before {
//     content: url(assets/images/dash/IRF-26x26.png);
//   }
//   .p-menuitem:nth-child(1) .p-menuitem-icon:before {
//     content: url(assets/images/dash/IRF-26x26.png);
//   }
// }

// .p-menuitem:nth-child(1) .p-menuitem-icon:before {
//   content: url(assets/images/dash/caseSumIcn.png);
// }
// .p-menuitem:nth-child(2) .p-menuitem-icon:before {
//   content: url(assets/images/dash/uploadDocIcn.png);
// }
// .p-menuitem:nth-child(3) .p-menuitem-icon:before {
//   content: url(assets/images/dash/myNoticeIcn.png);
// }
// .p-menuitem:nth-child(4) .p-menuitem-icon:before {
//   content: url(assets/images/dash/contact-county.png);
// }
// .p-menuitem:nth-child(5) .p-menuitem-icon:before {
//   content: url(assets/images/dash/alerts-communication.png  );
// }
// }
.left-menu.p-panelmenu {
  .p-icon-wrapper {
    display: inline-flex;
    position: absolute;
    right: 0;
  }
}
.left-menu.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-panelmenu-icon {
  margin-right: 0.5rem;
  color: #999;
}

.left-menu .submenu {
  position: relative;
}

.left-menu.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 1rem;
}

.minHeight {
  min-height: 0px !important;
}

.custom-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  max-width: 50%;
  max-height: 50%;
  overflow: visible;
  margin-top: -10px;
  .custom-tooltip-content {
    color: #fff;
    background: #1776cb;
    border: 1px solid #1776cb;
    position: relative;
    top: 0;
    left: 0;
    padding: 0.4rem;
    font-size: 0.8rem;
    max-width: 30rem;
    &:after,
    &:before {
      top: 50%;
      left: -17px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 8px;
      margin-top: -8px;
    }
    &:after {
      border-right-color: #1776cb;
    }
    &:before {
      border-right-color: #1776cb;
    }
  }
}

.goog-te-gadget .goog-te-combo {
  width: 100%;
  padding: 0.525rem;
  border-radius: 3px;
  border: none !important;
  font-size: 1rem;
  font-family: "SourceSansProSemiBold";
}

.translatePanel {
  position: absolute;
  right: 0px;
  top: 330px;
  background: #001f3b;
  color: #fff;
  padding: 1rem;
  z-index: 99999999999;
  width: 500px;
  text-align: justify;
  border-top: 3px solid #3077b8;
}
a.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.translate_disclaimer {
  margin-top: 0rem;
}

.translatePanelClose {
  background: none;
  color: #fff;
  padding: 10px;
}

.translatePanel:before {
  content: "";
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #3077b8 transparent;
  position: absolute;
  top: -10px;
  right: 3rem;
}

//.customPaginator

.p-paginator {
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0;
  font-size: 0.83rem !important;
  display: block !important;
  a {
    color: #333 !important;
  }
  .p-paginator-icon {
    font-size: 0.83rem !important;
  }

  .p-paginator-pages .p-paginator-page.ui-state-active {
    background-color: #cacaca !important;
    color: #ffffff;
  }
  .p-paginator-element.p-disabled {
    background: #fff;
    opacity: 0.5;
  }
  .p-paginator-pages .p-paginator-page,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: 2rem !important;
    height: 2rem !important;
  }
}

body .p-overlaypanel:before,
body .p-overlaypanel:after {
  border-bottom-color: #1776cb;
}

body .p-overlaypanel.p-overlaypanel-flipped:before,
body .p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #1776cb;
}

ul.bulletin,
ul.bulletin li {
  list-style: inside !important;
}

.document-stepper {
  padding: 0px 1rem;
  background: #f2f5f8;
}

.unfinishedLink {
  display: inline-block;
}

.no-pad-top-bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  legend {
    padding-right: 0px;
  }
  legend,
  .nj-sub-headerstyle {
    font-size: 20px !important;
  }
  .nj-headerstyle,
  .nj-tab-content-style {
    padding: 1rem !important;
  }
  .btn-gap {
    margin-right: 1rem;
  }
  .nj-box-alignment {
    padding-right: 0% !important;
  }
  .headerSection {
    position: relative;
  }
  .or-txt {
    position: relative;
    left: 0.5rem; // 3rem
  }

  .btn-draft .p-button-text {
    display: none;
  }

  .btn-draft button.p-button {
    padding: 15px;
  }

  .nj-panel-content {
    // padding: 0.5rem 1rem;
    padding-left: 0.5rem !important;
  }

  .resource .nj-panel-content {
    padding-left: 0.5rem;
  }

  .ui-breadcrumb ul li {
    display: block;
    border-bottom: 1px solid #f9f9f9;
    padding: 1rem;
    background: #fcfcfc;
    margin: 0;
  }
  .ui-breadcrumb-chevron {
    display: none !important;
  }

  .translatePanel {
    width: 100% !important;
  }
  .document-stepper {
    padding: 0px 1rem;
    background: #f2f5f8;
  }

  .unfinishedLink {
    display: block;
    margin-bottom: 1rem;
  }
  .paddingRight0px {
    padding-right: 0px;
  }
  .fieldset {
    padding-inline-end: 0;
  }

  .hideOnMobile {
    display: none !important;
  }

  body .p-tabview.p-tabview-top .p-tabview-nav li {
    margin-right: 1rem;
  }

  .gridActionColumn {
    width: 100px;
    cursor: pointer !important;
    // float:right;
    .gridEdit,
    .gridDelete {
      margin: 0 5px;
    }
  }

  .nj-help {
    text-align: inherit !important;
  }

  .nj-child-indent {
    padding-left: 0.3rem;
  }

  body .p-accordion .p-accordion-content {
    padding: 0.3rem !important;
  }

  .reviewEditBtnStyle {
    position: absolute;
    right: 30px !important;
  }

  .reviewEditBtnStyle button {
    padding: 0;
    background: #fff !important;
    border: none !important;
  }

  .reviewEditBtnStyle .p-button-icon-left {
    color: var(--button-bg);
    font-size: 1.3rem;
  }

  .reviewEditBtnStyle .p-button-text {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .nj-headerstyle,
  .nj-tab-content-style {
    padding: 1rem !important;
  }
  .document-stepper {
    padding: 0px 1rem;
    background: #f2f5f8;
  }
  body .p-tabview.p-tabview-top .p-tabview-nav li {
    margin-right: 2rem;
  }
}

@media screen and (min-width: 1360px) and (max-width: 1680px) {
  .nj-headerstyle,
  .nj-tab-content-style {
    // padding: 1rem !important;
  }
}

.label-value .label-val {
  font-weight: 700 !important;
  margin-left: 0.5rem;
}

@media screen and (min-width: 768px) and (max-width: 1920px) {
  .wrapper-label-value.lbl-fixed {
    display: table;
  }
  .wrapper-label-value.lbl-fixed .label-value {
    display: table-row;
  }
  .wrapper-label-value.lbl-fixed .label-value label {
    display: table-cell;
    padding-right: 0.5rem;
    white-space: nowrap;
  }

  .wrapper-label-value.lbl-fixed .label-value span {
    display: table-cell;
    position: relative;
    padding-left: 1rem;
  }

  .wrapper-label-value.lbl-fixed .label-value span:after {
    content: ":";
    position: absolute;
    left: 0;
    top: 0;
  }
}

.form-sub-header-small {
  font-size: 1.1rem;
  font-family: "LatoBold";
  color: #303946;
}

.editBtnStyle {
  float: right;
}

app-review-label.indent label {
  padding-left: 1rem !important;
}

.label-value {
  margin-bottom: 0.3rem;
  display: contents;
}

.indent-small {
  margin-left: 1rem !important;
}

.reviewEditBtnStyle {
  position: absolute;
  right: 0;
  top: 0;
}

.reviewContent {
  position: relative !important;
}

label.requiredLabel {
  padding: 15px 0px;
}

.hidden {
  visibility: hidden;
  display: none;
}

.dashboardHeaderStyle {
  //padding: 25px 10px;
  background: #edf0f3;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--grid-header-text-color) !important;

  //border-left: 5px solid #ddd;

  padding: 25px;
  background: linear-gradient(#fff 50%, #edf0f3);
  background: linear-gradient(to right, #edf0f3 10%, #fff);

  // box-shadow: 0 4px 10px -9px #ccc;

  color: #13416a !important;
  border-radius: 5px;
}

.p-steps
  .p-steps-item.ui-state-complete:not(.incomplete).p-disabled
  .p-steps-title {
  opacity: 0.6 !important;
}

body .p-datepicker table td > a.ui-state-active {
  color: #fff !important;
}
.field-error {
  color: var(--error-color);
  font-size: 0.9rem;
  padding: 0.2rem 0.43rem;
  background: #fff1f1;
  white-space: normal;

  span.pi {
    font-size: 0.9rem;
    padding-right: 0.4rem;
    top: 2px;
    position: relative;
  }
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 0.75rem !important;
  // padding: 3px;
}

body .p-checkbox .p-checkbox-box.ui-state-active {
  border-color: var(--input-icon-bg) !important;
  background-color: var(--input-icon-bg) !important;
  color: #ffffff;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: var(--input-icon-bg) !important;
}

body .p-paginator {
  background: #fff;
  border: none;
  text-align: right;
}

body lib-normal-grid.admin-grid p-table .ui-table .ui-table-thead th {
  background: #edf0f3 !important;
  vertical-align: top;
}

.screenLabelText {
  padding-right: 0.5rem !important;
}

.smallCaps {
  font-variant-caps: small-caps;
  white-space: nowrap;
  line-height: 1rem;
  font-size: 1.1rem !important;
  font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia,
    serif;
}

.smallCaps.divFont,
.smallCaps.deptFont {
  font-size: 0.85rem !important;
}

.headerTranslateStyle {
  margin: 0px !important;
}

body
  .registerTab
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  a {
  color: #000 !important;
  background: var(--accordion-header-hover-bg) !important;
}

body
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  a
  .p-accordion-toggle-icon {
  color: #333 !important;
}

.sign-in-container {
  // box-shadow: 1px 1px 10px 0px #356ea9 !important;
  box-shadow: 0px 0px 10px 0px #124470 !important;
  border: none !important;
}

p-table .ui-table .ui-table-thead tr th p-sorticon {
  font-size: 0.8rem !important;
}

.agencyRowData td {
  vertical-align: top !important;
}

custom-select select.ui-dropdown.ng-pristine.ng-invalid {
  border: 1px solid #ec4d4d !important;
  border-left-width: 5px !important;
}

.selectStyle {
  width: 100%;
  color: #333333;
  padding: 0.429em;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-title select {
  font-size: 0.9rem !important;
  padding: 0.2rem;
  border-color: #a6a6a6;
  color: #333;
}

//.goog-te-banner-frame {display: none !important;}
// .goog-te-banner-frame.skiptranslate {visibility: hidden !important;
//   top: -40px;
//   }
// .goog-te-banner-frame {visibility: hidden !important;}
//body#body_main{ top: 0px !important; }

.loginpage {
  background-image: url("assets/images/loginBg_new2.png") !important;
}

.steps-item:not(.active).completed:not(.disabled) {
  .steps-number {
    border: none !important;
  }
  .steps-number::before {
    content: "";
    background: url("assets/images/tick.png") !important;
    background-size: 2em !important;
    position: absolute;
    height: 2em;
    width: 2em;
    left: 0;
    top: 0;
    background-repeat: no-repeat !important;
    margin: 0 !important;
  }
}

.steps-item:not(.active).completed.error:not(.disabled),
.steps-item:not(.active).completed.error.disabled {
  .steps-number {
    border: none !important;
  }
  .steps-number::before {
    content: "";
    background: url("assets/images/untick.png") !important;
    background-size: 2em !important;
    position: absolute;
    height: 2em;
    width: 2em;
    left: 0;
    top: 0;
    background-repeat: no-repeat !important;
    margin: 0 !important;
  }
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}

.nj-logo {
  align-items: center;
}

.componentLayer {
  left: 0 !important;
}

.lblwithradiotype2 .radiolbl .fieldLabel {
  font: 1rem SourceSansProBold;
  margin: 0;
  position: relative;
}

.gridColumnStyle {
  word-break: break-all !important;
}

.gridHeader .gridColumnStyle {
  word-break: break-word !important;
}

.no-wrap {
  white-space: nowrap !important;
}

@media screen and (min-width: 0px) and (max-width: 550px) {
  .confirmDialog.p-dialog {
    width: 98% !important;
  }

  .no-wrap {
    white-space: normal !important;
  }
}

.banner-link-style {
  color: #014078 !important;
}

h1.logo {
  margin-bottom: 0rem !important;
}

.pleaseWaitMsg .loader {
  background: url(assets/images/loader.gif) 0 0/25% no-repeat !important;
  background-position-x: center !important;
}

.feedbackPanel {
  text-align: justify;
  ul {
    padding: 1rem;
  }

  ul,
  li {
    list-style-type: initial !important;
  }
}

.ownInputStyle .inputFieldContainer {
  padding-bottom: 1rem;
}
.queryInfoContainer {
  margin: 0.5rem;
  max-height: 250px;
  overflow: auto;
  border: 1px solid #ccc;
  padding: 1rem;
}

.siteTour {
  padding-bottom: 0px !important;
  a {
    cursor: pointer;
  }
  .playIcon {
    cursor: pointer;
    font-size: 3rem;
    padding-right: 1rem;
  }
}

.applyVideo {
  a {
    cursor: pointer;
  }
  .playIcon {
    cursor: pointer;
    font-size: 3rem;
    padding-right: 1rem;
    width: 4rem;
  }
}

.p-tabview .p-tabview-panels {
  padding: 0px !important;
}

body .p-inputtext {
  color: #333333;
  background: #ffffff;
  padding: 0.429em !important;
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px !important;
}

body .p-tabview .p-tabview-panels {
  color: #333 !important;
}

body .p-component {
  font-family: var(--primary-font);
  font-size: 1rem;
}

body .p-calendar .p-datepicker-trigger.p-button.p-component.p-button-icon-only {
  background: var(--input-icon-bg) !important;
  border-color: var(--input-icon-bg) !important;
  height: 2.24rem;
  width: 2.357em;
  position: relative;
  top: 0px;
  border: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

body button.p-button {
  display: inline-block;
  position: relative;
  padding: 0;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  overflow: visible;
  color: white !important;
  background-color: var(--button-bg) !important;
  border: 1px solid var(--button-bg) !important;
  border-radius: 5px !important;
  margin: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  width: inherit;
  padding: 0.429em 1em 0.429em 1em;
  .p-button-label {
    display: inline;
    line-height: normal;
    font-weight: 400;
  }

  .p-button-icon-left {
    //   position: absolute;
    // top: 50%;
    // margin-top: -0.5em;
    // height: 1em;
    // left: 0.5em;
    display: inline-block;
  }
}

body .ui-dropdown {
  background: #ffffff;
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px !important;
}

body .p-button.p-button-text-only .p-button-text {
  padding: 0.5vh 0.7vw;
  font-size: 1vw;
}

body .p-radiobutton-label {
  margin-left: 0.5rem;
  margin-bottom: 0;
  align-self: end;
}

body .p-component p {
  line-height: 1.5;
  margin: 0;
}

/* p-dialog */

body .p-dialog-header {
  color: #5c5c5c !important;
  background-color: #f2f2f2 !important;
  border-top: 5px solid #1ebf06;
}

body .p-dialog-title p-header {
  color: #5c5c5c !important;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1rem 1rem 1rem;
}

.radioBtnStyle {
  padding: 0rem !important;
  display: flex;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a {
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #eef7ff !important;
}

p-checkbox {
  vertical-align: inherit !important;
}

.p-steps .p-steps-item:before {
  border: none;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
  padding: 0.429em 1em 0.429em 1em;
  border-radius: 5px;
}

.ng-pristine.ng-invalid .p-radiobutton-box {
  border-color: #ec4d4d !important;
}

.p-fileupload {
  .p-button-label {
    font-weight: 400 !important;
    letter-spacing: normal;
    line-height: normal;
  }
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #3373cb;
  color: #ffffff;
  border-color: #3373cb;
}

.p-autocomplete {
  display: flex;
}

.p-dialog .p-dialog-header {
  padding: 1rem !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1rem !important;
}

.p-dialog.confirmDialog {
  .p-dialog-title {
    text-transform: uppercase;
  }
}

body label.checkbox-right-label {
  padding-left: 0.5rem !important;
  font-family: var(--primary-font);
  top: 4px;
  position: relative;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #79818d;
}

.p-component:not(.p-button):disabled {
  opacity: 1;
  background: #efeaea;
}

.p-panelmenu-header.p-highlight .p-menuitem-text {
  color: #fff !important;
}

.showColumIcon {
  position: relative;
  top: 7px;
}

body .p-dialog .p-dialog-content {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: #5c5c5c !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #95c6f3;
}

.radio-group lib-field-container div div {
  display: inherit !important;
  float: right;
}

.disclaimerPage legend {
  background: transparent !important;
}

.thumbnailPanel.img4 {
  background: #fb3859 !important;
  border: 3px solid #e3052a !important;
}

.thumbnailPanel.img4:hover {
  background: #b10824 !important;
  content: attr(title);
}

.loginpage {
  background-image: url("assets/images/loginBg_new2.png");
  height: 100%;
  width: 100%;
}

.skiptranslate.skiptranslate:not(.goog-te-gadget) {
  display: none !important;
}
body {
  top: 0px !important;
}

.skiptranslate.goog-te-gadget {
  a {
    display: none;
  }
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}

.thumbnail label {
  display: inline;
}

.pinkWidget {
  background: #fb3859;
  border: 3px solid #e3052a;
}

.pinkWidget:hover {
  background: #b10824;
  content: attr(title);
}
.greenWidget {
  background: #6dc538;
  border: 3px solid #4fa81a;
}

.greenWidget:hover {
  background: #417622;
  content: attr(title);
}

.blueWidget {
  background: #5d90ef;
  border: 3px solid #386ac5;
}

.blueWidget:hover {
  background: #224076;
}

.orangeWidget {
  background-color: #ffb400;
  border: 3px solid #f49b00;
}

.orangeWidget:hover {
  background: #996c00;
  content: attr(title);
}

lib-checkbox > div {
  display: flex;
}

body label.checkbox-right-label {
  top: 2px !important;
}
body .p-checkbox .p-checkbox-box {
  top: 4px !important;
  position: relative;
}

p-checkbox {
  align-items: inherit !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 10px 15px;
  border-radius: 20px;
  margin: 1rem;

  &.p-menuitem-link-active {
    position: relative;
    background: #61a6e5 !important;
    border: 1px solid transparent !important;
  }
}

.caseView app-review-label {
  label {
    display: block !important;
    font-size: 0.9rem !important;
    color: #61a6e5 !important;
    margin-bottom: 0;
  }

  span {
    display: block !important;
    padding-left: 0rem !important;
    margin-left: 0rem !important;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .indent-small {
    margin-left: 0.2rem !important;
  }

  .left-menu.p-panelmenu .p-panelmenu-header > a {
    padding: 1rem 3rem !important;
  }
}

.p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none;
}

.left-menu.p-panelmenu .p-panelmenu-header > a {
  border: 1px solid transparent;
  background: #fff !important;
  font-size: 1.2rem;
  font-weight: normal;
  padding: 22px 30px 22px 2rem !important;
  border-radius: 0px !important;
  color: #333 !important;
  transition: all 0.25s ease-in;
  cursor: pointer !important;
}

.left-menu.p-panelmenu .p-panelmenu-header.p-highlight > a {
  position: relative;
  background: #61a6e5 !important;
  border: 1px solid transparent !important;
  color: #fff !important;
}

.p-panelmenu-panel .menuHeader.p-panelmenu-header {
  a {
    background: #edf0f3 !important;
    // color: #5c5c5c !important;
    cursor: default !important;
    pointer-events: none;
    // padding: 10px 2rem !important;
    // margin-top:1rem;
  }
}

.p-panelmenu .p-panelmenu-content {
  // padding-left : 3rem !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding-left: 5rem !important;
}

.left-menu.p-panelmenu .caseList.p-panelmenu-header > a {
  padding-left: 4rem !important;
}

.left-menu.p-panelmenu .caseList.p-highlight > a {
  background: #fff !important;
  color: #333 !important;
  color: #0064be !important;

  .p-menuitem-text {
    color: #333 !important;
    color: #0064be !important;
  }
}

.left-menu.p-panelmenu .caseList.p-highlight > a:before {
  background: #fff !important;
}

.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link.p-menuitem-link-active:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  // background: #0064be;
  left: 0px;
  top: 0;
}

.messageField .fa-question-circle {
  padding-left: 5px;
}

// .disclaimerPage{
//     overflow-y: auto;
//     height: 600px;
//     padding: 0.5rem;
//     border: 1px solid #ccc;
//     border-radius: 3px;
//     margin-bottom: 1rem;
//     box-shadow: 0px 0px 10px 1px #ccc;
// }

// .CCAPNote {
//   background: #ffeded;
//   /* border: 1px solid #ecadad; */
//   padding: 0.5rem;
//   /* border-radius: 5px; */
//   color: #b90202;
//   /* font-style: italic; */
//   box-shadow: 0px 3px 9px -1px #ccc;
//   border-left: 5px solid;
// }

.wordBreak {
  word-break: break-word !important;
}

.redStatusText {
  background-color: #ff3333;
  padding: 5px !important;
  color: #fff;
}

.greenStatusText {
  background-color: #50c150;
  padding: 5px !important;
  color: #fff;
}

// .HouseholdGrid {
//   .gridColumnStyle{
//     font-size: 16px !important;
//   }
// }

.noteStyle {
  background: #fbfbfb;
  padding: 1rem !important;
  border-left: 5px solid #d2dbe5;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #1e4e83;
  box-shadow: 0px 7px 6px -8px #ccc;
  text-align: justify;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 0.5rem 0;
}

// app-home-page {
//   .banner-top,
//   .btn-toggle,
//   .btn-toggle-down {
//     display: none;
//   }
// }

.sliderContent {
  a {
    color: #014078 !important;
  }
  a:hover {
    text-decoration: none !important;
    color: #3b99e7 !important;
  }
}

.disclaimerBar {
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    text-shadow: none !important;
  }

  .p-carousel-prev-icon,
  .p-carousel-next-icon {
    font-size: 1rem !important;
    color: #5c5c5c;
  }
}

app-banner-view {
  .closeIcon {
    display: inline-flex;
    padding: 5px !important;
  }
}

.left-menu.p-panelmenu {
  .p-panelmenu-content {
    margin-left: 4rem;
    border: none;
    // border-left: 1px solid #dee2e6;
    box-shadow: -10px 0 14px -17px #4a4a4a;

    .p-menuitem .p-menuitem-link {
      padding-left: 1rem !important;
      margin: 0.5rem;
    }
  }

  .p-panelmenu-header-link .p-panelmenu-icon {
    zoom: 0.75;
  }

  a.p-panelmenu-header-link .p-panelmenu-icon {
    color: #777 !important;
  }
  a.p-menuitem-link .p-panelmenu-icon {
    color: #777 !important;
  }

  a.p-panelmenu-header-link.p-menuitem-link-active .p-panelmenu-icon {
    color: #0064be !important;
  }

  a.p-menuitem-link.p-menuitem-link-active .p-panelmenu-icon {
    color: #0064be !important;
  }
}

.left-menu.p-panelmenu .caseList.p-panelmenu-header > a {
  /* padding-left: 4rem !important; */
  background: #edf0f3 !important;
  border-radius: 20px !important;
  margin-left: 2rem;
  padding: 15px 15px !important;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.gap2rem{
  margin-top: 2rem !important;
}

.link_white{
  color: #fff !important;
  text-decoration-color: #fff !important;
}

@media screen and (min-width: 375px) and (max-width: 900px) {
  // .p-carousel-content {
  //   overflow: hidden !important;
  // }
  // body .p-carousel .p-carousel-indicators .p-carousel-indicator button {
  //   background: #ccc;
  // }

  // body .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  //   background-color: #3B82F6;
  // }
  
  // .p-carousel .p-carousel-indicators {
  //   background: #ffffff;
  //   top: 5px;
  //   bottom: 0;
  //   padding: 0;
  //   left: auto;
  //   right: 0;
  //   width: 100%;
  //   position: relative;
  // }
  .gap2rem{
    margin-top: 1rem !important;
  }
  // .banner-subtext{
  //   padding-top: 0rem !important;
  // }
}



@media screen and (min-width: 375px) and (max-width: 500px) {
  .banner-content-inner {
    padding-left: 2rem !important;
    width: 90%;
  }
  body .p-carousel .p-carousel-content .p-carousel-prev{
    left : -10px !important;
  }
  body .p-carousel .p-carousel-content .p-carousel-next{
    right : -10px !important;
  }
}

