@font-face {
  font-family: 'icomoon';  
  src: url('font/icomoon.woff?m5w0oe') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
    font-family: 'SourceSansPro';   
    src: url('font/SourceSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
  font-family: 'SourceSansProSemiBold';   
  src: url('font/SourceSansPro-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'SourceSansProBold';   
  src: url('font/SourceSansPro-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'LatoBold';   
  src: url('font/Lato-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'RobotoRegular';   
  src: url('font/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ctrl:before {
  content: "\ea50";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-phone:before {
  content: "\f095";
}
.icon-cloud-upload:before {
  content: "\f0ee";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}



@font-face {
  font-family: 'njicon';
  src: url('font/njicon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="nj-icn-"], [class*=" nj-icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'njicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nj-icn-health:before {
  content: "\e900";
}
.nj-icn-home:before {
  content: "\f015";
}
.nj-icn-laptop:before {
  content: "\f109";
}
.nj-icn-CompleteInterview:before {
  content: "\e901";
}
.nj-icn-CreditCard:before {
  content: "\e902";
}
.nj-icn-agingService:before {
  content: "\e903";
}
.nj-icn-careerService:before {
  content: "\e904";
}
.nj-icn-childCare:before {
  content: "\e905";
}
.nj-icn-njChildSupport:before {
  content: "\e906";
}
.nj-icn-SubmitApplication:before {
  content: "\e907";
}
.nj-icn-UploadDocuments:before {
  content: "\e908";
}
.nj-icn-coin-dollar:before {
  content: "\e93b";
}
