body {
  .ui-steps {
    .ui-state-disabled.ui-steps-item .ui-menuitem-link .ui-steps-number {
      color: #000;
      line-height: 30px;
    }
    .ui-steps .ui-state-disabled {
      opacity: $opacity * 2;
    }
  }
}

.ccrt-stepper .ui-steps-item {
  width: 20% !important;
  position: relative;
}
.ccrt-stepper .ui-steps-item:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 50%;
  top: 39%;
  height: 3px;
  background: #eee;
}
.ccrt-stepper .ui-steps-item:last-child:before {
  content: none !important;
}
body .ccrt-stepper.ui-steps:before {
  content: none !important;
  border: none !important;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  font-family: "robotobold";
  margin-top: 15px;
  color: #000;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background: #fff;
  color: var(--active-color);
  border: 2px solid;
  border-color: var(--active-color);
  font-family: "robotobold";
  line-height: 25px;
  position: relative;
}
body .ui-state-complete.ui-state-disabled.ui-steps-item .ui-steps-number {
  background: var(--active-color);
  color: white !important;
  border: 2px solid;
  border-color: var(--active-color);
  font-family: var(--primary-font);//"primeicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5em;
}
// body
//   .ui-state-complete.ui-state-disabled.ui-steps-item
//   .ui-steps-number:before {
//   content: "";// "\e909";
//   background: var(--active-color);
//   position: absolute;
//   left: 2px;
//   top: 0px;
//   border-radius: 50%;
// }
body .ui-state-complete.ui-state-disabled.ui-steps-item .ui-steps-title {
  color: #4360a8;
}
.ccrt-stepper .ui-steps-item.ui-state-complete:before {
  background: var(--active-color);
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number:before {
  content: "";
  border: 6px solid transparent;
  border-top: 7px solid;
  border-top-color: var(--active-color);
  position: absolute;
  top: 104%;
  left: 46%;
  margin-left: -5px;
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  width: 32px;
  height: 32px;
}
body .ui-steps .ui-steps-item .ui-menuitem-link {
  cursor: pointer !important;
}
