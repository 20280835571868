@font-face {
  font-family: "njkids";
  src: url("font/nv/nvkids.ttf?botlqy") format("truetype"),
    url("font/nv/nvkids.woff?botlqy") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="nv-"],
[class*=" nv-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "njkids" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nv-checkmark:before {
  content: "\e9d2";
}
.nv-workphone:before {
  content: "\e9e2";
}
.nv-fax:before {
  content: "\f1ac";
}
.nv-chevron-up:before {
  content: "\e9b2";
}
.nv-confidential2:before {
  content: "\e9ff";
}
.nv-help21:before {
  content: "\ea00";
}
.nv-source_income2:before {
  content: "\ea01";
}
.nv-left_arrow:before {
  content: "\e9e5";
}
.nv-right_arrow:before {
  content: "\e9e6";
}
.nv-add_special_alert1:before {
  content: "\e9e7";
}
.nv-add_special_alert2:before {
  content: "\e9e8";
}
.nv-assets:before {
  content: "\e9e9";
}
.nv-assistance_type:before {
  content: "\e9ea";
}
.nv-case1:before {
  content: "\e9eb";
}
.nv-case_category:before {
  content: "\e9ec";
}
.nv-case_identifier:before {
  content: "\e9ed";
}
.nv-case_type:before {
  content: "\e9ee";
}
.nv-closure_reason:before {
  content: "\e9ef";
}
.nv-confidential:before {
  content: "\e9f0";
}
.nv-docket_number:before {
  content: "\e9f1";
}
.nv-family_violence:before {
  content: "\e9f2";
}
.nv-help4:before {
  content: "\e9f3";
}
.nv-intergovemmental:before {
  content: "\e9f4";
}
.nv-intergovernmental:before {
  content: "\e9f5";
}
.nv-office:before {
  content: "\e9f6";
}
.nv-order_status:before {
  content: "\e9f7";
}
.nv-participant1:before {
  content: "\e9f8";
}
.nv-primary_function:before {
  content: "\e9f9";
}
.nv-recovery_type:before {
  content: "\e9fa";
}
.nv-source_income:before {
  content: "\e9fb";
}
.nv-special_alert:before {
  content: "\e9fc";
}
.nv-tools1:before {
  content: "\e9fd";
}
.nv-view_all1:before {
  content: "\e9fe";
}
.nv-disbursement_register:before {
  content: "\e9d3";
}
.nv-disbursement_register2:before {
  content: "\e9d4";
}
.nv-docketInfo2:before {
  content: "\e9d5";
}
.nv-download:before {
  content: "\e9d6";
}
.nv-download_pdf:before {
  content: "\e9d7";
}
.nv-download_xls:before {
  content: "\e9d8";
}
.nv-edit-01:before {
  content: "\e9d9";
}
.nv-employerPortal_websiteInfo:before {
  content: "\e9da";
}
.nv-maintainRole_screenAccess:before {
  content: "\e9db";
}
.nv-refundCheck_activityReport:before {
  content: "\e9dc";
}
.nv-refundReturn_receipts:before {
  content: "\e9dd";
}
.nv-releaseHold_receipts:before {
  content: "\e9de";
}
.nv-selfAssessment_reporting:before {
  content: "\e9df";
}
.nv-unidentifed_receipts:before {
  content: "\e9e0";
}
.nv-column:before {
  content: "\e9d0";
}
.nv-timer2:before {
  content: "\e9cf";
}
.nv-expand:before {
  content: "\e9c9";
}
.nv-hide_all:before {
  content: "\e9ca";
}
.nv-refresh:before {
  content: "\e9cb";
}
.nv-refresh2:before {
  content: "\e9cc";
}
.nv-timer:before {
  content: "\e9cd";
}
.nv-view_all:before {
  content: "\e9ce";
}
.nv-flag2:before {
  content: "\e9c4";
}
.nv-input_type2:before {
  content: "\e9c5";
}
.nv-notification:before {
  content: "\e9c6";
}
.nv-phoneno:before {
  content: "\e9c7";
}
.nv-tools2-01:before {
  content: "\e9c8";
}
.nv-addicon:before {
  content: "\e9be";
}
.nv-calender_agenda:before {
  content: "\e9bf";
}
.nv-calender_dayview:before {
  content: "\e9c0";
}
.nv-calender_monthview:before {
  content: "\e9c1";
}
.nv-calender_weekview:before {
  content: "\e9c2";
}
.nv-scheduler_edit2:before {
  content: "\e9c3";
}
.nv-arrears:before {
  content: "\e9b3";
}
.nv-arrears_payment:before {
  content: "\e9b4";
}
.nv-current_support:before {
  content: "\e9b5";
}
.nv-disbursement_hold:before {
  content: "\e9b6";
}
.nv-distributionHold:before {
  content: "\e9b7";
}
.nv-intergovermental:before {
  content: "\e9b8";
}
.nv-scheduler_edit:before {
  content: "\e9b9";
}
.nv-scheduler_gridview:before {
  content: "\e9ba";
}
.nv-scheduler_view:before {
  content: "\e9bb";
}
.nv-status:before {
  content: "\e9bc";
}
.nv-type:before {
  content: "\e9bd";
}
.nv-caseid:before {
  content: "\e9b0";
}
.nv-ssn:before {
  content: "\e9b1";
}
.nv-address:before {
  content: "\e9a0";
}
.nv-bad_check_indicator:before {
  content: "\e9a1";
}
.nv-circle-thin:before {
  content: "\f1db";
}
.nv-deceased:before {
  content: "\e9a2";
}
.nv-dob:before {
  content: "\e9a3";
}
.nv-docketno:before {
  content: "\e9a4";
}
.nv-email:before {
  content: "\e9a5";
}
.nv-employers:before {
  content: "\e9a6";
}
.nv-has_insurance:before {
  content: "\e9a7";
}
.nv-institution_status:before {
  content: "\e9a8";
}
.nv-location_status:before {
  content: "\e9a9";
}
.nv-medical_support:before {
  content: "\e9aa";
}
.nv-memberid:before {
  content: "\e9ab";
}
.nv-name:before {
  content: "\e9ac";
}
.nv-participant_id:before {
  content: "\e9ad";
}
.nv-phoneno-01:before {
  content: "\e9ae";
}
.nv-public_assistance:before {
  content: "\e9af";
}
.nv-alert1:before {
  content: "\e99a";
}
.nv-help1:before {
  content: "\e99b";
}
.nv-input_type:before {
  content: "\e99c";
}
.nv-report:before {
  content: "\e99d";
}
.nv-customerService_portal:before {
  content: "\e996";
}
.nv-helpdesk_ticket:before {
  content: "\e997";
}
.nv-polices_procedures:before {
  content: "\e998";
}
.nv-reporting_analytics:before {
  content: "\e999";
}
.nv-Intergovernmental:before {
  content: "\e98f";
}
.nv-case:before {
  content: "\e989";
}
.nv-logout1:before {
  content: "\e98a";
}
.nv-logout2:before {
  content: "\e98b";
}
.nv-preferred_theme1:before {
  content: "\e98c";
}
.nv-profile1:before {
  content: "\e98d";
}
.nv-scrollable_header:before {
  content: "\e98e";
}
.nv-acc_arrowDwn:before {
  content: "\e900";
}
.nv-acc_arrowUp:before {
  content: "\e901";
}
.nv-action:before {
  content: "\e902";
}
.nv-alert:before {
  content: "\e903";
}
.nv-archive_management:before {
  content: "\e904";
}
.nv-assignment_method:before {
  content: "\e905";
}
.nv-batch_receipts:before {
  content: "\e906";
}
.nv-batchStatus_logError:before {
  content: "\e907";
}
.nv-BenchWarrantList:before {
  content: "\e908";
}
.nv-billing_history:before {
  content: "\e909";
}
.nv-cancel:before {
  content: "\e90a";
}
.nv-case_logo:before {
  content: "\e90b";
}
.nv-caseInitiation:before {
  content: "\e90c";
}
.nv-caseManagement:before {
  content: "\e90d";
}
.nv-caseManagement2:before {
  content: "\e90e";
}
.nv-caseProcess:before {
  content: "\e90f";
}
.nv-caseSummary:before {
  content: "\e910";
}
.nv-check_voids_rejects:before {
  content: "\e911";
}
.nv-clear:before {
  content: "\e912";
}
.nv-clear2:before {
  content: "\e913";
}
.nv-clear3:before {
  content: "\e914";
}
.nv-collectionBatch_posting:before {
  content: "\e915";
}
.nv-complianceSchedule:before {
  content: "\e916";
}
.nv-create_maintenceCase:before {
  content: "\e917";
}
.nv-dailyGross_receiptsReport:before {
  content: "\e918";
}
.nv-dailyGross_receiptsReport2:before {
  content: "\e919";
}
.nv-delete:before {
  content: "\e91a";
}
.nv-disbursement_holdManagement:before {
  content: "\e91b";
}
.nv-disbursement_holdManagement2:before {
  content: "\e91c";
}
.nv-disbursement_holdReport:before {
  content: "\e91d";
}
.nv-disbursement_view:before {
  content: "\e91e";
}
.nv-distributionHold_receiptsReport:before {
  content: "\e91f";
}
.nv-docketInfo:before {
  content: "\e920";
}
.nv-document_process:before {
  content: "\e921";
}
.nv-document_reference:before {
  content: "\e922";
}
.nv-document_reprintRequest:before {
  content: "\e923";
}
.nv-done:before {
  content: "\e924";
}
.nv-electronic_document:before {
  content: "\e925";
}
.nv-electronicFund_transferInstruction:before {
  content: "\e926";
}
.nv-electronicFund_transferReport:before {
  content: "\e927";
}
.nv-enforcement:before {
  content: "\e928";
}
.nv-enforcement2:before {
  content: "\e929";
}
.nv-enforcement3:before {
  content: "\e92a";
}
.nv-establishment2:before {
  content: "\e92b";
}
.nv-establishment3:before {
  content: "\e92c";
}
.nv-events:before {
  content: "\e92d";
}
.nv-federal_information_processingStands:before {
  content: "\e92e";
}
.nv-fee_processing:before {
  content: "\e92f";
}
.nv-filter:before {
  content: "\e930";
}
.nv-filter1:before {
  content: "\e931";
}
.nv-filter2:before {
  content: "\e932";
}
.nv-financial:before {
  content: "\e933";
}
.nv-financial_eventLog:before {
  content: "\e934";
}
.nv-financial_summary:before {
  content: "\e935";
}
.nv-financial2:before {
  content: "\e936";
}
.nv-flag:before {
  content: "\e937";
}
.nv-fund_recipientRecoupment:before {
  content: "\e938";
}
.nv-geneticTest:before {
  content: "\e939";
}
.nv-grid_view:before {
  content: "\e93a";
}
.nv-grid_view2:before {
  content: "\e93b";
}
.nv-guideline_calculation:before {
  content: "\e93c";
}
.nv-help:before {
  content: "\e93d";
}
.nv-help2:before {
  content: "\e93e";
}
.nv-help3:before {
  content: "\e93f";
}
.nv-income_expense:before {
  content: "\e940";
}
.nv-initiation:before {
  content: "\e941";
}
.nv-links:before {
  content: "\e942";
}
.nv-list_view-01:before {
  content: "\e943";
}
.nv-listView:before {
  content: "\e944";
}
.nv-locate:before {
  content: "\e945";
}
.nv-locate_data:before {
  content: "\e946";
}
.nv-locate_pending_resolution:before {
  content: "\e947";
}
.nv-locate_requests:before {
  content: "\e948";
}
.nv-locate_source:before {
  content: "\e949";
}
.nv-locate2:before {
  content: "\e94a";
}
.nv-maintain_pendingReferrals:before {
  content: "\e94b";
}
.nv-maintain_process:before {
  content: "\e94c";
}
.nv-manage_batchParameter:before {
  content: "\e94d";
}
.nv-manageHold_Instruction:before {
  content: "\e94e";
}
.nv-manageVitalStatsInfo:before {
  content: "\e94f";
}
.nv-master_calendar:before {
  content: "\e950";
}
.nv-menu:before {
  content: "\e951";
}
.nv-monthlyGrant_modification:before {
  content: "\e952";
}
.nv-more:before {
  content: "\e953";
}
.nv-myCases:before {
  content: "\e954";
}
.nv-noteProcess:before {
  content: "\e955";
}
.nv-obligation_arrearsAdjustment:before {
  content: "\e956";
}
.nv-obligation_arrearsAdjustment2:before {
  content: "\e957";
}
.nv-orderWizard:before {
  content: "\e958";
}
.nv-other_partyInfo:before {
  content: "\e959";
}
.nv-overview:before {
  content: "\e95a";
}
.nv-participant:before {
  content: "\e95b";
}
.nv-participant_address:before {
  content: "\e95c";
}
.nv-participant_address2:before {
  content: "\e95d";
}
.nv-participant_assets:before {
  content: "\e95e";
}
.nv-participant_demographics:before {
  content: "\e95f";
}
.nv-participant_insurance:before {
  content: "\e960";
}
.nv-participant_license:before {
  content: "\e961";
}
.nv-participant_merge:before {
  content: "\e962";
}
.nv-participant_programHistory:before {
  content: "\e963";
}
.nv-participant_sourceIncome:before {
  content: "\e964";
}
.nv-portal:before {
  content: "\e965";
}
.nv-preferred_theme:before {
  content: "\e966";
}
.nv-process_taskReference:before {
  content: "\e967";
}
.nv-process_taskReference2:before {
  content: "\e968";
}
.nv-profile:before {
  content: "\e969";
}
.nv-quick_navigation:before {
  content: "\e96a";
}
.nv-receipt_process:before {
  content: "\e96b";
}
.nv-receipt_reposting:before {
  content: "\e96c";
}
.nv-receiptReversal_activityReport:before {
  content: "\e96d";
}
.nv-receipts_history:before {
  content: "\e96e";
}
.nv-reference_master:before {
  content: "\e96f";
}
.nv-rejectedRecordDetails:before {
  content: "\e970";
}
.nv-restrictedCase_participant:before {
  content: "\e971";
}
.nv-reverse_repostReceipts:before {
  content: "\e972";
}
.nv-save:before {
  content: "\e973";
}
.nv-search:before {
  content: "\e974";
}
.nv-search_participant:before {
  content: "\e975";
}
.nv-search2:before {
  content: "\e976";
}
.nv-searchForCases:before {
  content: "\e977";
}
.nv-serviceProcessHistory:before {
  content: "\e978";
}
.nv-setting:before {
  content: "\e979";
}
.nv-source_incomeInformation:before {
  content: "\e97a";
}
.nv-source_incomeMerge:before {
  content: "\e97b";
}
.nv-start_process:before {
  content: "\e97c";
}
.nv-summary_view:before {
  content: "\e97d";
}
.nv-support_log:before {
  content: "\e97e";
}
.nv-svc_instructions:before {
  content: "\e97f";
}
.nv-system_messages:before {
  content: "\e980";
}
.nv-system_messages1:before {
  content: "\e981";
}
.nv-taxOffset_infomationUpdate:before {
  content: "\e982";
}
.nv-tools:before {
  content: "\e983";
}
.nv-tools2:before {
  content: "\e984";
}
.nv-user_maintenance:before {
  content: "\e985";
}
.nv-widgetView:before {
  content: "\e986";
}
.nv-worker:before {
  content: "\e987";
}
.nv-worklist:before {
  content: "\e988";
}
.nv-other-phone:before {
  content: "\e9e3";
}
.nv-cell-phone:before {
  content: "\e9e4";
}
.nv-arrow_drop_down:before {
  content: "\e9d1";
}
.nv-controller-play:before {
  content: "\e992";
}
.nv-controller-play2:before {
  content: "\e993";
}
.nv-controller-next:before {
  content: "\e994";
}
.nv-controller-jump-to-start:before {
  content: "\e995";
}
.nv-arrow-down:before {
  content: "\e991";
}
.nv-check:before {
  content: "\e990";
}
.nv-chevron-left:before {
  content: "\e99e";
}
.nv-home-phone:before {
  content: "\e9e1";
}
.nv-alert2:before {
  content: "\e99f";
}
.nv-angle-double-right:before {
  content: "\f101";
}
.nv-angle-right:before {
  content: "\f105";
}
.nv-angle-down:before {
  content: "\f107";
}
